/* eslint-disable import/prefer-default-export */
import {
  SET_SPEAKER_TIMER,
  SET_STAGE,
  SET_STAGE_STATUS,
} from "~~/redux/actionList";

export const setStageStatus = (status) => ({
  type: SET_STAGE_STATUS,
  status,
});

export const setStage = (stageState) => ({
  type: SET_STAGE,
  stageState,
});

export const setSpeakerTimer = (timer) => ({
  type: SET_SPEAKER_TIMER,
  timer,
});
