/**
 * @fileoverview The container for all of WelcomeAV state. Aggregates data
 * from WelcomeAV sub-contexts to hook everything together, and outputs a
 * single provider that can be used to initialize all WelcomeAV state.
 */
import PropTypes from "prop-types";
import React from "react";
import * as AudioContainer from "~~/welcomeav/audio/AudioContainer";
import * as StreamingContainer from "~~/welcomeav/streaming/StreamingContainer";

const WelcomeAVProviderSig = {
  propTypes: {
    children: PropTypes.element.isRequired,
    // TODO: All of this should be managed within WelcomeAV & not passed in props
    localClients: PropTypes.shape().isRequired,
    streamVolumeDelegate: PropTypes.shape().isRequired,
  },
};

export default function WelcomeAVProvider(props) {
  return (
    <StreamingContainer.Provider localClients={props.localClients}>
      <AudioContainer.Provider
        delegate={props.streamVolumeDelegate} // TODO: Will be handled internally
      >
        {props.children}
      </AudioContainer.Provider>
    </StreamingContainer.Provider>
  );
}

WelcomeAVProvider.propTypes = WelcomeAVProviderSig.propTypes;
