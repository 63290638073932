import {
  ADD_LOUNGE_ROOM,
  ADD_LOUNGE_ROOMS,
  CLEAR_LOUNGE_ROOMS,
  REMOVE_LOUNGE_ROOMS,
  SET_LOUNGE_ROOMS,
} from "~~/redux/actionList";

export const setLoungeRooms = (loungeRooms) => ({
  type: SET_LOUNGE_ROOMS,
  loungeRooms,
});

export const addLoungeRoom = (loungeRoom) => ({
  type: ADD_LOUNGE_ROOM,
  loungeRoom,
});

export const addLoungeRooms = (loungeRooms) => ({
  type: ADD_LOUNGE_ROOMS,
  loungeRooms,
});

export const removeLoungeRooms = (loungeRoomIds) => ({
  type: REMOVE_LOUNGE_ROOMS,
  loungeRoomIds,
});

export const clearLoungeRooms = () => ({
  type: CLEAR_LOUNGE_ROOMS,
});
