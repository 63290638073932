// Needs to match backend from stream_service.
// The "value" in this case isn't an identifier, but the multiplier for the ID bucket.
// See userService for more information on buckets.
const CLIENT_TYPE = {
  PRIMARY: 0,
  SCREEN: 1,
  SECONDARY: 2,
  SECONDARY_SCREEN: 3,
};

export default CLIENT_TYPE;

export function getTokenKeyForClientType(clientType) {
  // Breakout and lounge tokens are passed in on the fly; these ones are created beforehand
  switch (clientType) {
    case CLIENT_TYPE.PRIMARY:
      return "primary";
    case CLIENT_TYPE.SCREEN:
      return "screen";
    default:
      return null;
  }
}
