export const ACCEPTABLE_IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/svg+xml",
];
export const ACCEPTABLE_VIDEO_TYPES = ["video/mp4", "video/mpeg"];
export const ACCEPTABLE_AUDIO_TYPES = ["audio/mp3", "audio/mpeg"];
export const ACCEPTABLE_SOUND_EFFECT_TYPES = [
  ...ACCEPTABLE_AUDIO_TYPES,
  "audio/x-wav",
  "audio/wave",
  "audio/wav",
  "audio/vnd.wave",
];
export const ACCEPTABLE_VIRTUAL_BACKGROUND_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/svg+xml",
];
export const ACCEPTABLE_CHAT_MIME_TYPES = [
  ...ACCEPTABLE_IMAGE_TYPES,
  "application/pdf",
];
