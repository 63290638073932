import {
  ADD_BREAKOUT_ROOM,
  ADD_BREAKOUT_ROOMS,
  REMOVE_REGISTRATION_FROM_ROOM,
  REMOVE_SINGLE_BREAKOUT_ROOM,
  SET_BREAKOUT_ROOMS,
} from "~~/redux/actionList";

export const setBreakoutRooms = (sessionId, rooms) => ({
  type: SET_BREAKOUT_ROOMS,
  sessionId,
  rooms,
});

export const addBreakoutRooms = (sessionId, rooms) => ({
  type: ADD_BREAKOUT_ROOMS,
  sessionId,
  rooms,
});

export const addBreakoutRoom = (sessionId, room) => ({
  type: ADD_BREAKOUT_ROOM,
  sessionId,
  room,
});

export const removeRegistrationFromRoom = (
  sessionId,
  roomId,
  registrationId
) => ({
  type: REMOVE_REGISTRATION_FROM_ROOM,
  sessionId,
  roomId,
  registrationId,
});

export const removeBreakoutRoom = (sessionId, roomId) => ({
  type: REMOVE_SINGLE_BREAKOUT_ROOM,
  sessionId,
  roomId,
});
