/* eslint-disable no-case-declarations */
/**
 * @fileoverview Manages the event management channel
 */
import EventEmitter from "events";
import AV_PERMISSION_REQUEST_TYPE from "~~/constants/avPermissionsRequestType";
import CLIENT_TYPE from "~~/constants/clientType";
import { updateBreakoutRoom } from "~~/redux/actions/breakoutRoom";
import { updateBreakoutSession } from "~~/redux/actions/breakoutSession";
import { updateBreakoutSession as updateSingleBreakoutSession } from "~~/redux/actions/breakoutSessions";
import { getGreenRoomStreams } from "~~/redux/selectors/video";
import store from "~~/redux/store";
import { fetchBreakoutRoom } from "~~/services/breakoutService";
import { setLoungeSessionWithoutRooms } from "~~/services/loungeService";
import * as RegistrationService from "~~/services/registrationService";
import {
  removeAllLocalStreams,
  removeClient,
  setLocalStreamAudioMuted,
  setLocalStreamVideoMuted,
} from "~~/services/videoService";
import CustomCable from "~~/utils/CustomCable";
import { getFeatureFlag } from "~~/utils/featureFlags";
import {
  getClientTypeFromStreamId,
  getRegIdFromStreamId,
} from "~~/utils/streamUtils";
import WLog from "~~/wlog";

// Constants
const CABLE_CHANNEL = "EventManagementChannel";
const MSG_TYPE = {
  ANNOUNCEMENT: "announcement",
  AV_PERMISSIONS_REJECTED: "avPermissionsRejected",
  AV_PERMISSIONS_REQUEST_TIMED_OUT: "avPermissionsRequestTimedOut",
  BAN: "ban",
  BREAKOUT_ROOM_INVITE: "breakoutRoomInvite",
  BREAKOUT_ROOM_UPDATED: "breakoutRoomUpdated",
  BREAKOUT_ROOMS_CLOSE_EARLY: "breakoutRoomsCloseEarly",
  BREAKOUT_SESSION_CLOSED: "breakoutSessionClosed",
  BREAKOUT_SESSION_OPENED: "breakoutSessionOpened",
  BRING_TO_GREEN_ROOM: "bring-to-green-room",
  KICK_FROM_LOUNGE: "kickFromLounge",
  KICK: "kick",
  KICK_BECAUSE_EVENT_ENDED: "kickBecauseEventEnded",
  LOUNGE_SESSION_CLOSED: "loungeSessionClosed",
  LOUNGE_SESSION_OPENED: "loungeSessionOpened",
  MUTE_VIDEO: "mutePeerVideo",
  MUTE: "mutePeer",
  // Removes a speaker from the green room
  SKEDADDLE: "skedaddle",
  SOUND_EFFECT_PLAYED: "soundEffectPlayed",
  SPEAKER_JOINED: "speakerJoined",
  SWITCH_TO_SPECTATOR: "switchToSpectator",
  UNMUTE_VIDEO: "unmutePeerVideo",
  UNMUTE: "unmutePeer",
};

let subscription = null;

const emitter = new EventEmitter();
const events = [
  "breakoutInvitationReceived",
  "breakoutSwapReceived",
  "breakoutKickReceived",
  "announcementReceived",
  "loungeSessionOpened",
  "loungeSessionOpenedAnnouncement",
  "loungeSessionClosed",
  "bringToGreenRoom",
  "switchToSpectator",
  "kickFromLounge",
  "kickBecauseEventEnded",
  "kickedByRemoteLogin",
  "bannedByEventProducer",
  "breakoutSessionClosed",
  "breakoutSessionOpened",
  "speakerJoined",
  "soundEffectPlayed",
];
export const EVENT_TYPE = {
  LOUNGE_SESSION_OPENED: "loungeSessionOpened",
  LOUNGE_SESSION_CLOSED: "loungeSessionClosed",
};

export async function onEventEvent(event, callback) {
  if (events.indexOf(event) !== -1) {
    emitter.on(event, callback);
  }
}

export async function off(event, callback) {
  emitter.off(event, callback);
}

export async function sendAnnouncement(announcement) {
  subscription?.perform("send_announcement", { announcement });
}

export async function emitLocalAnnouncement(announcement) {
  emitter.emit("announcementReceived", announcement);
}

export const mute = (streamId) => {
  subscription?.perform("mute", { streamId });
};

export const unmute = (streamId) => {
  subscription?.perform("unmute", { streamId });
};

export const muteVideo = (streamId) => {
  subscription?.perform("mute_video", { streamId });
};

export const unmuteVideo = (streamId) => {
  subscription?.perform("unmute_video", { streamId });
};

export const skedaddle = (streamId) => {
  subscription?.perform("send_skedaddle", { streamId });
};

export const switchToSpectator = (streamId) => {
  subscription?.perform("switch_to_spectator", { streamId });
};

export const kickFromLounge = (regId) => {
  subscription?.perform("kick_from_lounge", { regId });
};

export const bringToGreenRoom = (regId) => {
  subscription?.perform("send_bring_to_green_room", { regId });
};

export const banRegistration = (registrationId) => {
  subscription?.perform("ban_registration", { registrationId });
};

export const broadcastPlaySoundEffect = (soundEffectAssetId) => {
  subscription?.perform("play_sound_effect", { soundEffectAssetId });
};

export const userJumpedIn = (registrationId) => {
  subscription?.perform("user_jumped_in", { registrationId });
};

/**
 * Subscribe to event management websocket channel. This lets us know when relevant events are broadcasted
 */
export async function subscribe(
  stageId,
  signedStageId,
  eventId,
  mobile = false
) {
  const cable = new CustomCable(
    {
      channel: CABLE_CHANNEL,
      stage_id: stageId,
      signed_stage_id: signedStageId,
      event_id: eventId,
      mobile,
      // Anycable doesn't send the user agent over,
      // so pass user agent in part of the connection params
      user_agent: navigator.userAgent,
    },
    {
      received: async (data) => {
        const { Event, Registrations, User } = store.getState();

        // noinspection JSUnreachableSwitchBranches
        switch (data.messageType) {
          case MSG_TYPE.AV_PERMISSIONS_REJECTED:
            const user = Registrations[data.message.eventRegistrationId];
            if (data.message.eventRegistrationId !== User.registration.id) {
              window.flash_messages.flashError(
                `${user.firstName} ${user.lastName} denied turning on their ${
                  data.message.avPermissionRequestType ===
                  AV_PERMISSION_REQUEST_TYPE.VIDEO
                    ? "camera"
                    : "mic"
                }.`
              );
            }
            break;
          case MSG_TYPE.AV_PERMISSIONS_REQUEST_TIMED_OUT:
            if (data.message.eventRegistrationId !== User.registration.id) {
              window.flash_messages.flashError(
                `Request to turn on ${
                  data.message.avPermissionRequestType ===
                  AV_PERMISSION_REQUEST_TYPE.VIDEO
                    ? "camera"
                    : "mic"
                } timed out.`
              );
            }
            break;
          case MSG_TYPE.BREAKOUT_ROOM_UPDATED:
            fetchBreakoutRoom();
            break;
          case MSG_TYPE.BREAKOUT_ROOM_INVITE: {
            let inviteType = "breakoutInvitationReceived";
            const { stageId: _stageId, breakoutRoomId } = data.message;
            const { BreakoutRoom } = store.getState();

            // Ignore breakout invitations for stages that don't apply to me
            if (_stageId !== stageId) {
              break;
            }

            if (BreakoutRoom && BreakoutRoom.id) {
              inviteType = "breakoutSwapReceived";
              setTimeout(
                () =>
                  emitLocalAnnouncement(
                    "You've been transferred to a different breakout room"
                  ),
                3000
              );
            }
            if (data.message == null) {
              inviteType = "breakoutKickReceived";
              setTimeout(
                () =>
                  emitLocalAnnouncement(
                    "You've been removed from the breakout room"
                  ),
                3000
              );
            }

            store.dispatch(updateBreakoutRoom({ id: breakoutRoomId }));
            emitter.emit(inviteType);
            break;
          }
          case MSG_TYPE.ANNOUNCEMENT:
            emitLocalAnnouncement(data.message);
            break;
          case MSG_TYPE.BREAKOUT_ROOMS_CLOSE_EARLY:
            // eslint-disable-next-line no-case-declarations
            const openedBreakoutSession = Object.values(
              store.getState().BreakoutSessions
            ).find((session) => {
              return session && session.status === "opened";
            });
            if (openedBreakoutSession) {
              store.dispatch(
                updateSingleBreakoutSession({
                  durationInSeconds: data.message,
                  id: openedBreakoutSession.id,
                })
              );
            }

            store.dispatch(
              updateBreakoutRoom({ durationInSeconds: data.message }),
              updateBreakoutSession({ durationInSeconds: data.message })
            );
            break;
          case MSG_TYPE.LOUNGE_SESSION_OPENED:
            setLoungeSessionWithoutRooms(data.loungeSession);
            if (data.announcement) {
              emitter.emit(
                "loungeSessionOpenedAnnouncement",
                data.announcement
              );
            }
            break;

          case MSG_TYPE.LOUNGE_SESSION_CLOSED:
            // Attendee Lounge view will clear data, producer will fetch next lounge session
            emitter.emit(EVENT_TYPE.LOUNGE_SESSION_CLOSED);
            break;

          case MSG_TYPE.MUTE:
            setLocalStreamAudioMuted(data.message, true);
            break;
          case MSG_TYPE.UNMUTE:
            if (
              getFeatureFlag("speakerOptInEnabled") &&
              Event.organization.speakerOptInToTurnOnAvEnabled
            ) {
              break;
            }
            setLocalStreamAudioMuted(data.message, false);
            break;
          case MSG_TYPE.MUTE_VIDEO:
            setLocalStreamVideoMuted(data.message, true);
            break;
          case MSG_TYPE.UNMUTE_VIDEO:
            if (
              getFeatureFlag("speakerOptInEnabled") &&
              Event.organization.speakerOptInToTurnOnAvEnabled
            ) {
              break;
            }
            setLocalStreamVideoMuted(data.message, false);
            break;
          case MSG_TYPE.SKEDADDLE:
            /* eslint-disable-next-line no-case-declarations */
            const clientType = getClientTypeFromStreamId(data.message);

            if (clientType === CLIENT_TYPE.SCREEN) {
              removeClient(clientType);
            } else {
              removeAllLocalStreams();
            }

            break;
          case MSG_TYPE.SWITCH_TO_SPECTATOR:
            emitter.emit("switchToSpectator");
            break;
          case MSG_TYPE.KICK_FROM_LOUNGE:
            if (data.stageId !== stageId) {
              break;
            }

            emitter.emit("kickFromLounge");
            break;
          case MSG_TYPE.BRING_TO_GREEN_ROOM:
            emitter.emit("bringToGreenRoom");
            break;
          case MSG_TYPE.KICK:
            if (data.stageId !== stageId) {
              break;
            }

            emitter.emit("kickedByRemoteLogin");
            break;
          case MSG_TYPE.KICK_BECAUSE_EVENT_ENDED:
            emitter.emit("kickBecauseEventEnded");
            break;
          case MSG_TYPE.BAN:
            emitter.emit("bannedByEventProducer");
            break;
          case MSG_TYPE.BREAKOUT_SESSION_OPENED:
            emitter.emit("breakoutSessionOpened");
            break;
          case MSG_TYPE.BREAKOUT_SESSION_CLOSED:
            emitter.emit("breakoutSessionClosed");
            break;
          case MSG_TYPE.SPEAKER_JOINED:
            if (getFeatureFlag("disableSpeakerNotifications")) {
              break;
            }

            const currentReg = store.getState().User.registration;
            if (currentReg.registrationType !== "staff") {
              break;
            }

            const registration =
              store.getState().Registrations[data.registrationId];

            const greenRoomStreams = getGreenRoomStreams(store.getState());
            const greenRoomRegIds = greenRoomStreams.map((stream) =>
              getRegIdFromStreamId(stream.getId())
            );

            if (registration) {
              // We consistently see these notifications during live events via
              // https://github.com/gather-wholesale/adair/blob/b6728dc9a39db6a0f0b0ccdd5e15dba1b246d031/app/channels/stage_presence_channel.rb#L25
              // and they are noisy and distracting So as a fallback safeguard, don't emit
              // if the speaker is in the green room (i.e. we are certain they are
              // already in the event)
              // https://welcomeonlineco.slack.com/archives/C010ZA4LA64/p1665687245966189
              if (greenRoomRegIds.includes(registration.id)) {
                break;
              }

              emitter.emit("speakerJoined", { registration });
            } else {
              RegistrationService.fetchReg(data.registrationId)
                .then(() => {
                  const reg =
                    store.getState().Registrations[data.registrationId];

                  if (!reg) {
                    WLog.assertionFailure(
                      "eventmanagement",
                      "Registration not present in Redux state after RegistrationService.fetchReg succeeded"
                    );
                    return;
                  }

                  // We consistently see these notifications during live events via
                  // https://github.com/gather-wholesale/adair/blob/b6728dc9a39db6a0f0b0ccdd5e15dba1b246d031/app/channels/stage_presence_channel.rb#L25
                  // and they are noisy and distracting So as a fallback safeguard,
                  // don't emit if the speaker is in the green room (i.e. we are certain
                  // they are already in the event)
                  // https://welcomeonlineco.slack.com/archives/C010ZA4LA64/p1665687245966189
                  if (greenRoomRegIds.includes(reg.id)) {
                    return;
                  }

                  emitter.emit("speakerJoined", { registration: reg });
                })
                .catch((err) => {
                  WLog.log(
                    "warn",
                    "eventmanagement",
                    `Failed to fetch registration ${data.registrationId}`,
                    err
                  );
                });
            }
            break;
          case MSG_TYPE.SOUND_EFFECT_PLAYED:
            emitter.emit("soundEffectPlayed", data.soundEffectAssetId);
            break;
          default:
            WLog.log(
              "warn",
              "cable.eventmanagement",
              `Unknown message: ${data.messageType}`
            );
        }
      },
    }
  );
  await cable.subscribe();
  subscription = cable;
}
export async function subscribeRecorder(
  stageId,
  signedStageId,
  eventId,
  mobile = false
) {
  subscription = new CustomCable(
    {
      channel: CABLE_CHANNEL,
      stage_id: stageId,
      signed_stage_id: signedStageId,
      event_id: eventId,
      mobile,
      // Anycable doesn't send the user agent over,
      // so pass user agent in part of the connection params
      user_agent: navigator.userAgent,
    },
    {
      received: async (data) => {
        switch (data.messageType) {
          case MSG_TYPE.SOUND_EFFECT_PLAYED:
            emitter.emit("soundEffectPlayed", data.soundEffectAssetId);
            break;
        }
      },
    }
  );
  await subscription.subscribe();
}

export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
}
