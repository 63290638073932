import {
  SET_CHAT_CHANNEL_NOTIFICATION,
  SET_PEOPLE_NOTIFICATION,
  SET_POLL_NOTIFICATION,
  SET_QNA_NOTIFICATION,
} from "~~/redux/actionList";

export const setChatChannelNotification = (key, panelNotificationType) => ({
  type: SET_CHAT_CHANNEL_NOTIFICATION,
  key,
  panelNotificationType,
});

export const setQnaNotification = (panelNotificationType) => ({
  type: SET_QNA_NOTIFICATION,
  panelNotificationType,
});

export const setPeopleNotification = (panelNotificationType) => ({
  type: SET_PEOPLE_NOTIFICATION,
  panelNotificationType,
});

export const setPollNotification = (panelNotificationType) => ({
  type: SET_POLL_NOTIFICATION,
  panelNotificationType,
});
