import {
  SET_CURRENT_LOUNGE_ROOM_ID,
  SET_CURRENT_LOUNGE_ROOM_SPECTATING,
} from "~~/redux/actionList";

export const setCurrentLoungeRoomId = (id) => ({
  type: SET_CURRENT_LOUNGE_ROOM_ID,
  id,
});

export const setCurrentLoungeRoomSpectating = (spectating) => ({
  type: SET_CURRENT_LOUNGE_ROOM_SPECTATING,
  spectating,
});
