/**
 * @fileoverview Middleware for Agora 4.x clients and APIs related to them
 */
import * as FeatureFlags from "~~/utils/featureFlags";
import ClientManager from "~~/welcomeav/streaming/agoraMiddleware/clientManager";
import JoinTimeoutError from "~~/welcomeav/streaming/models/errors/joinTimeoutError";
import RCClient from "~~/welcomeav/streaming/models/rcClient";
import WLog from "~~/wlog";

const Constants = {
  CLIENT_JOIN_TIMEOUT_MS: 8000,
};

/**
 * Creates and initializes a WelcomeAV client.
 *
 * @param {RCClientConfig} clientConfig client config. Keys defined below
 * @returns {Promise<RCClient>} Client object
 */
export async function createClient(
  clientConfig,
  clientManager = ClientManager.getInstance()
) {
  const {
    appId, // string
    clientId, // number
    channel, // string
    token, // string
    channelMode, // string
    encryptionSecret, // string?
    // TODO: Proxy handling should be all internal
    eagerUseProxy, // boolean. Proxy will be used as a fallback by default
    // TODO: This is only for test streams (which we do want to sub to). After migration
    // WelcomeAV user should have more control over subscribe logic
    shouldLocalClientsSubscribe = false,
    /** Callback to be called before the client joins. Passes RCClient */
    onBeforeJoin = (_client) => {},

    /*
     * The value supplied by AgoraAvailabilityService will be referenced here
     * to determine if the best path to connect to Agora is via proxy.
     *
     * Only when the resolved scheme from the service is "proxy" should
     * we connect to proxy.
     */
    agoraResolvedScheme,
    clientRoleOptionsLevel, // the latency level of the client
  } = clientConfig;

  const { releaseAgoraAvailabilityService, releaseVp9 } =
    FeatureFlags.getFeatureFlags();

  const codec = releaseVp9 ? "vp9" : "vp8";

  AgoraRTC.setParameter("SUBSCRIBE_TCC", false);

  WLog.log(
    "debug",
    "welcomeav.streaming",
    `Initializing client ${clientId} in channel ${channel} with mode ${channelMode}, codec ${codec}, clientRoleOptions.level ${clientRoleOptionsLevel}`
  );

  const client = AgoraRTC.createClient({
    mode: channelMode,
    codec: codec,
    clientRoleOptions: {
      level: clientRoleOptionsLevel,
    },
  });

  if (encryptionSecret != null) {
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Enabling encryption for client ${clientId} in channel ${channel}`
    );
    client.setEncryptionConfig("aes-128-xts", encryptionSecret);
  }

  if (eagerUseProxy && !releaseAgoraAvailabilityService) {
    const proxyVersion = FeatureFlags.get("releaseProxyVersion5") ? 5 : 4;
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Starting cloud proxy version ${proxyVersion} for client ${clientId} in channel ${channel}`
    );
    client.startProxyServer(proxyVersion);
  }

  if (releaseAgoraAvailabilityService && agoraResolvedScheme === "proxy") {
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Starting cloud proxy version 5 for client ${clientId} in channel ${channel} via AgoraAvailabilityService`
    );
    client.startProxyServer(5);
  }

  const rcClient = new RCClient(
    /* clientId */ undefined,
    client,
    channel,
    channelMode
  );
  onBeforeJoin(rcClient);

  const joinPromise = client.join(appId, channel, token, clientId);
  if (eagerUseProxy && !releaseAgoraAvailabilityService) {
    const id = await joinPromise;
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Client ${id} successfully joined channel ${channel}`
    );
    if (!shouldLocalClientsSubscribe) {
      clientManager.addClientId(id);
    }
    rcClient.clientId = id;
    return rcClient;
  }

  // On timeout, connection via proxy server
  const timeoutPromise = new Promise((_res, rej) =>
    setTimeout(
      () => rej(new JoinTimeoutError()),
      Constants.CLIENT_JOIN_TIMEOUT_MS
    )
  );

  try {
    const id = await Promise.race([timeoutPromise, joinPromise]);
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Client ${id} successfully joined channel ${channel}`
    );
    if (!shouldLocalClientsSubscribe) {
      clientManager.addClientId(id);
    }
    rcClient.clientId = id;
    return rcClient;
  } catch (error) {
    if (error.name !== JoinTimeoutError.NAME) {
      throw error;
    }

    await client.leave();

    if (releaseAgoraAvailabilityService) {
      throw new Error(`Error joining the channel ${error}`);
    }

    // Reattempt w/ proxy enabled
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Join channel ${channel} timed out for client ${clientId}; attempting to join with proxy`
    );
    return createClient(
      {
        ...clientConfig,
        eagerUseProxy: true,
      },
      clientManager
    );
  }
}
