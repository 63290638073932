/**
 * @fileoverview helper for events for the Agora 3.x to Agora 4.x migration.
 *
 * Events can be found here:
 * https://docs.agora.io/en/Video/API%20Reference/web/interfaces/agorartc.client.html#on
 */
import ClientManager from "~~/welcomeav/streaming/agoraMiddleware/clientManager";
import AgoraV3Event from "~~/welcomeav/streaming/models/agoraV3Event";
import RCStream from "~~/welcomeav/streaming/models/rcStream";
import WLog from "~~/wlog";

/**
 * Binds Agora 4.x events (from a 4.x client object) to an event emitter
 * which emits Agora 3.x events
 *
 * @param {ClientSubscriptionManager} subscriptionManager Subscription
 * manager for the client. Shared state
 * @param {AgoraRTCClient} agoraClient 4.x client to listen to events on
 * @param {EventEmitter} eventEmitter Event emitter for sending 3.x events
 */
export function bindV4EventsToEmitter(
  subscriptionManager,
  agoraClient,
  eventEmitter
) {
  // Mapping of remote user uid => { audio: bool, video: bool }
  const hasRemoteUserPublished = {};
  // Mapping of remote user uid => stream ID. Temporary; since we know that
  // remote streams will only ever be created here, we can safely manage this
  // state here. Longer terms solution will be needed
  const uidToRcStream = {};

  // This will be triggered when a remote track is enabled/disabled as well
  agoraClient.on("user-published", async (remoteUser, mediaType) => {
    const isLocal = ClientManager.getInstance().hasClientId(remoteUser.uid);
    const unmuteEvent =
      mediaType === "video"
        ? AgoraV3Event.UNMUTE_VIDEO
        : AgoraV3Event.UNMUTE_AUDIO;

    if (isLocal) {
      // In the future, each local client should be managing its own state. Since
      // our existing code has the primary client manage all stream states (including
      // for the primary screen), we need to do this.
      eventEmitter.emit(unmuteEvent);
      return;
    }

    if (!hasRemoteUserPublished[remoteUser.uid][mediaType]) {
      WLog.log(
        "debug",
        "welcomeav.streaming",
        `Remote stream ${remoteUser.uid} published ${mediaType}`
      );
      hasRemoteUserPublished[remoteUser.uid][mediaType] = true;
      if (uidToRcStream[remoteUser.uid]) {
        eventEmitter.emit(AgoraV3Event.REMOTE_STREAM_PUBLISHED, {
          stream: uidToRcStream[remoteUser.uid],
        });
      } else {
        const stream = RCStream.createRemoteStream(remoteUser);
        uidToRcStream[remoteUser.uid] = stream;
        eventEmitter.emit(AgoraV3Event.REMOTE_STREAM_PUBLISHED, { stream });
      }
    } else {
      WLog.log(
        "debug",
        "welcomeav.streaming",
        `Remote stream ${remoteUser.uid} unmuted ${mediaType}`
      );
    }

    /**
     * Always subscribe & emit the unmute events:
     *
     * After unmuting, we need to re-subscribe to the track & this is not
     * handled by legacy code.
     *
     * If we don't emit unmute events, even on an initial publish, UI mute
     * states may be wrong.
     *
     * If we emit them before we know for a fact that the stream has been
     * subscribed to, it can also cause UI mute states to be wrong
     */
    if (subscriptionManager.isSubscribed(remoteUser.uid)) {
      agoraClient
        .subscribe(remoteUser, mediaType)
        .then(() => eventEmitter.emit(unmuteEvent))
        .catch((error) => {
          WLog.log("warn", "welcomeav.streaming", "Failed to subscribe", error);
        });
    }
  });

  // This will be triggered when a remote track is enabled/disabled as well
  agoraClient.on("user-unpublished", async (remoteUser, mediaType) => {
    const isLocal = ClientManager.getInstance().hasClientId(remoteUser.uid);
    if (isLocal) {
      return;
    }

    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Remote stream ${remoteUser.uid} muted ${mediaType}`
    );
    eventEmitter.emit(
      mediaType === "video" ? AgoraV3Event.MUTE_VIDEO : AgoraV3Event.MUTE_AUDIO
    );
  });

  agoraClient.on("user-joined", async (remoteUser) => {
    const isLocal = ClientManager.getInstance().hasClientId(remoteUser.uid);
    if (isLocal) {
      return;
    }

    hasRemoteUserPublished[remoteUser.uid] = { audio: false, video: false };
    const stream = RCStream.createRemoteStream(remoteUser);
    uidToRcStream[remoteUser.uid] = stream;
    eventEmitter.emit(AgoraV3Event.REMOTE_STREAM_PUBLISHED, { stream });
  });

  agoraClient.on("user-left", async (remoteUser, _reason) => {
    const isLocal = ClientManager.getInstance().hasClientId(remoteUser.uid);
    if (isLocal) {
      return;
    }

    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Remote stream ${remoteUser.uid} unpublished`
    );
    delete hasRemoteUserPublished[remoteUser.uid];
    let stream = uidToRcStream[remoteUser.uid];
    if (!stream) {
      stream = RCStream.createRemoteStream(remoteUser);
    } else {
      delete uidToRcStream[remoteUser.uid];
    }

    subscriptionManager.removeSubscribedUid(remoteUser.uid);
    eventEmitter.emit(AgoraV3Event.PEER_LEFT, {
      uid: stream.getId(),
    });
    eventEmitter.emit(AgoraV3Event.REMOTE_STREAM_UNPUBLISHED, { stream });
  });
}
