import BREAKOUT_SESSION_STATUS from "~~/constants/breakoutSessionStatus";
import { PING_INTERVAL, POLL_PRESENCE_INTERVAL } from "~~/constants/presence";
import {
  clearBreakoutConfig,
  updateBreakoutConfig,
} from "~~/redux/actions/breakoutConfig";
import { setBreakoutPresenceForUsers } from "~~/redux/actions/breakoutPresence";
import {
  removeBreakoutRoom,
  setBreakoutRoom,
} from "~~/redux/actions/breakoutRoom";
import {
  addBreakoutRoom,
  addBreakoutRooms,
  removeRegistrationFromRoom,
  removeBreakoutRoom as removeSingleBreakoutRoom,
  setBreakoutRooms,
} from "~~/redux/actions/breakoutRooms";
import {
  removeBreakoutSession,
  setBreakoutSession,
  updateBreakoutSession as updateBreakoutSessionRedux,
} from "~~/redux/actions/breakoutSession";
import {
  addBreakoutSession,
  removeSingleBreakoutSession,
  setBreakoutSessions,
  updateBreakoutSession as updateSingleBreakoutSessionRedux,
} from "~~/redux/actions/breakoutSessions";
import {
  addRegistrations,
  removeRegistrationBreakoutRoomId,
  removeRegistrationBreakoutRoomIds,
} from "~~/redux/actions/registrations";
import store from "~~/redux/store";
import axios from "~~/utils/authenticatedAxios";
import cable from "~~/utils/cable";
import WLog from "~~/wlog";

// presence
const CHANNEL = "BreakoutChannel";
let interval = null;
let pingInterval = null;
let subscription = null;

// Private methods
function stage() {
  return store.getState().Stage;
}

async function setSessionData(data) {
  const { breakoutSession, breakoutRooms, registrations, ...config } = data;

  return Promise.all([
    store.dispatch(updateBreakoutConfig(config)),
    store.dispatch(setBreakoutSession(breakoutSession)),
    store.dispatch(addBreakoutSession(breakoutSession)),
    store.dispatch(setBreakoutRooms(breakoutSession.id, breakoutRooms)),
    store.dispatch(addRegistrations(registrations)),
  ]);
}

/**
 * Looks through all registrations to find ones that are NOT assigned to the
 * current session, and filters those registrations' assigned breakout rooms
 * to remove rooms belonging to that session.
 *
 * @param {*} sessionId The ID of the current breakout session
 * @param {*} assignedRegistrations An ID => registration map of all registrations
 * assigned to the current session
 */
function refreshRegistrationAssignedBreakoutRoomIds(
  sessionId,
  assignedRegistrations
) {
  const { Registrations: registrations, BreakoutRooms } = store.getState();
  const rooms = BreakoutRooms[sessionId];

  const updatedRegistrations = Object.values(registrations).reduce(
    (regs, nextReg) => {
      if (assignedRegistrations[nextReg.id]) {
        return regs;
      }

      /* eslint-disable-next-line no-param-reassign */
      regs[nextReg.id] = {
        ...nextReg,
        assignedBreakoutRoomIds: (nextReg.assignedBreakoutRoomIds || []).filter(
          (id) => !rooms[id]
        ),
      };

      return regs;
    },
    {}
  );

  store.dispatch(addRegistrations(updatedRegistrations));
}

export async function addRoomData(sessionId, data) {
  return Promise.all([
    store.dispatch(addBreakoutRoom(sessionId, data.breakoutRoom)),
    store.dispatch(addRegistrations(data.registrations)),
  ]);
}

export async function fetchBreakoutSession(sessionId, ignoreIfClosed = true) {
  const { id, eventId } = stage();
  const { data } = await axios.get(
    `/events/${eventId}/stages/${id}/breakout_sessions/${sessionId}`
  );

  const { breakoutSession, breakoutRooms, registrations } = data;
  if (
    ignoreIfClosed &&
    breakoutSession.status === BREAKOUT_SESSION_STATUS.CLOSED
  ) {
    return;
  }

  store.dispatch(addBreakoutSession(breakoutSession));
  store.dispatch(setBreakoutRooms(breakoutSession.id, breakoutRooms));
  store.dispatch(addRegistrations(registrations));
  refreshRegistrationAssignedBreakoutRoomIds(sessionId, registrations);
}

export async function createBreakoutSession(data) {
  const { id, eventId } = stage();
  const { data: breakoutData } = await axios.post(
    `/events/${eventId}/stages/${id}/breakout_sessions`,
    {
      ...data,
    }
  );

  store.dispatch(addBreakoutSession(breakoutData.breakoutSession));
  setSessionData(breakoutData);
  return breakoutData.breakoutSession;
}

export async function fetchAllBreakoutSessions() {
  const { id, eventId } = stage();
  const { data } = await axios.get(
    `/events/${eventId}/stages/${id}/breakout_sessions`
  );
  return store.dispatch(setBreakoutSessions(data));
}

export async function updateBreakoutSession(data, _sessionId) {
  const { id: stageId, eventId } = stage();
  const sessionId = _sessionId;

  const {
    data: { breakoutSession },
  } = await axios.patch(
    `/events/${eventId}/stages/${stageId}/breakout_sessions/${sessionId}`,
    data
  );

  store.dispatch(updateBreakoutSessionRedux(breakoutSession));
  store.dispatch(updateSingleBreakoutSessionRedux(breakoutSession));
  return Promise.resolve();
}

/**
 * Destroys the current breakout session
 */
export async function destroyBreakoutSession() {
  const { id, eventId } = stage();
  const { BreakoutSession: session } = store.getState();
  if (!session || session.id == null) {
    throw new Error("There is no breakout session");
  }

  await axios.delete(
    `/events/${eventId}/stages/${id}/breakout_sessions/${session.id}`
  );

  return Promise.all([
    store.dispatch(removeBreakoutSession()),
    store.dispatch(removeRegistrationBreakoutRoomIds()),
  ]);
}

export async function openBreakoutSession(_sessionId) {
  const { id, eventId } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_sessions/${sessionId}`,
    { status: "opened" }
  );

  store.dispatch(updateBreakoutSessionRedux(data.breakoutSession));
  store.dispatch(addBreakoutSession(data.breakoutSession));
  return Promise.resolve();
}

export async function updateBreakoutRoomsRemainingDuration(
  duration,
  announcement = "",
  _sessionId
) {
  const { id, eventId } = stage();
  let sessionId = _sessionId;
  if (!sessionId) {
    const { BreakoutSession: session } = store.getState();
    if (!session) {
      return Promise.reject(new Error("No breakout session ID"));
    }

    sessionId = session.id;
  }

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_sessions/${sessionId}`,
    {
      close_early_announcement: announcement,
      close_early_duration: duration,
    }
  );

  store.dispatch(updateBreakoutSessionRedux(data));
  store.dispatch(updateSingleBreakoutSessionRedux({ id: sessionId, ...data }));
  return Promise.resolve();
}

export async function createBreakoutRoom(_sessionId) {
  const { id, eventId } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.post(
    `/events/${eventId}/stages/${id}/breakout_rooms`,
    {
      breakoutSessionId: sessionId,
    }
  );

  await addRoomData(sessionId, data);
  return data.breakoutRoom;
}

export async function renameBreakoutRoom(room, name) {
  const { id, eventId } = stage();
  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_rooms/${room.id}`,
    { name }
  );
  return store.dispatch(
    addBreakoutRoom(data.breakoutRoom.breakoutSessionId, data.breakoutRoom)
  );
}

export async function deleteBreakoutSession(sessionId) {
  const { id: stageId, eventId } = stage();
  await axios.delete(
    `/events/${eventId}/stages/${stageId}/breakout_sessions/${sessionId}`
  );
  return store.dispatch(removeSingleBreakoutSession(sessionId));
}

export async function deleteBreakoutRoom(room, registrations) {
  const { id, eventId } = stage();
  await axios.delete(
    `/events/${eventId}/stages/${id}/breakout_rooms/${room.id}`
  );

  registrations.forEach((reg) => {
    store.dispatch(removeRegistrationBreakoutRoomId(reg.id, room.id));
  });
  return store.dispatch(
    removeSingleBreakoutRoom(room.breakoutSessionId, room.id)
  );
}

export async function swapParticipants(regId1, regId2, _sessionId) {
  const { id, eventId } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_sessions/${sessionId}`,
    {
      swapParticipants: [regId1, regId2],
    }
  );
  return Promise.all([
    store.dispatch(addBreakoutRooms(sessionId, data.breakoutRooms)),
    store.dispatch(addRegistrations(data.registrations)),
  ]);
}

export async function unassign(breakoutRoomId, regId, _sessionId) {
  const { id, eventId } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_rooms/${breakoutRoomId}`,
    {
      removeRegistration: regId,
    }
  );
  store.dispatch(removeRegistrationBreakoutRoomId(regId, breakoutRoomId));
  return addRoomData(sessionId, data);
}

export async function assignToRoom(
  breakoutRoomId,
  oldBreakoutRoomId,
  registration,
  _sessionId
) {
  const { id, eventId } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_rooms/${breakoutRoomId}`,
    {
      addRegistration: registration.id,
    }
  );

  if (oldBreakoutRoomId) {
    store.dispatch(
      removeRegistrationFromRoom(sessionId, oldBreakoutRoomId, registration.id)
    );
  }

  return addRoomData(sessionId, data);
}

export async function setLocalBreakoutRoom(room) {
  if (!room) {
    return Promise.resolve();
  }
  const { breakoutRoom, registrations, ...extras } = room;
  return Promise.all([
    store.dispatch(updateBreakoutConfig(extras)),
    store.dispatch(setBreakoutRoom(breakoutRoom)),
    store.dispatch(addRegistrations(registrations)),
  ]);
}

export async function fetchBreakoutRoom() {
  const room = store.getState().BreakoutRoom;
  if (!room || room.id == null) {
    return Promise.resolve();
  }

  const { eventId, id: stageId } = stage();
  const { data } = await axios.get(
    `/events/${eventId}/stages/${stageId}/breakout_rooms/${room.id}`
  );

  const { breakoutRoom, registrations, ...extras } = data;
  return Promise.all([
    store.dispatch(updateBreakoutConfig(extras)),
    store.dispatch(setBreakoutRoom(breakoutRoom)),
    store.dispatch(addRegistrations(registrations)),
  ]);
}

export async function pollPresenceChannel(_sessionId) {
  const { eventId, id: stageId } = store.getState().Stage;
  let breakoutSessionId = _sessionId;
  if (!breakoutSessionId) {
    const { BreakoutSession: session } = store.getState();
    if (!session) {
      return;
    }

    breakoutSessionId = session.id;
  }

  const url = `/events/${eventId}/stages/${stageId}/breakout_sessions/${breakoutSessionId}/breakout_presence`;
  const {
    data: { ids },
  } = await axios.get(url);
  store.dispatch(setBreakoutPresenceForUsers(ids));
  interval = setInterval(async () => {
    const {
      data: { ids: _ids },
    } = await axios.get(url);
    store.dispatch(setBreakoutPresenceForUsers(_ids));
  }, POLL_PRESENCE_INTERVAL);
}

export async function stopPollingPresenceChannel() {
  if (interval) {
    clearInterval(interval);
  }
}

export async function autoAssignBreakoutSession(_sessionId) {
  const { eventId, id } = stage();
  const sessionId = _sessionId;

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/breakout_sessions/${sessionId}`,
    { automaticallyPlaceParticipants: true }
  );

  return Promise.all([
    store.dispatch(updateBreakoutSessionRedux(data.breakoutSession)),
    store.dispatch(addBreakoutSession(data.breakoutSession)),
    store.dispatch(addBreakoutRooms(sessionId, data.breakoutRooms)),
    store.dispatch(addRegistrations(data.registrations)),
  ]);
}

export function closeLocalBreakoutSession(id) {
  store.dispatch(removeSingleBreakoutSession(id));
}

export function closeOpenedBreakoutSession() {
  const session = Object.values(store.getState().BreakoutSessions).find(
    (s) => s.status === BREAKOUT_SESSION_STATUS.OPENED
  );
  if (session) {
    WLog.log(
      "debug",
      "breakouts",
      `Closing opened breakout session ${session.id}`
    );
    store.dispatch(removeSingleBreakoutSession(session.id));
  }
}

/**
 * Clears all breakout state. For when the breakout rooms close
 */
export function clearBreakoutData() {
  WLog.log("debug", "breakouts", "Clearing breakout data");
  store.dispatch(removeBreakoutSession());
  store.dispatch(removeBreakoutRoom());
  store.dispatch(removeRegistrationBreakoutRoomIds());
  store.dispatch(clearBreakoutConfig());
  return Promise.resolve();
}

export async function createBreakoutSessionFromCsv(sessionData, file) {
  const { eventId, id: stageId } = stage();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("breakoutSession", JSON.stringify(sessionData));

  const { data } = await axios.post(
    `/events/${eventId}/stages/${stageId}/breakout_sessions/bulk_create`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );

  store.dispatch(addBreakoutSession(data.breakoutSession));
  setSessionData(data);
  return data.breakoutSession;
}

/**
 * Edits a breakout session with a CSV. The "sessionData" param here MUST contain
 * the ID of the breakout session to be updated.
 */
export async function updateBreakoutSessionWithCsv(sessionData, file) {
  const { eventId, id: stageId } = stage();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("breakoutSession", JSON.stringify(sessionData));

  const { data } = await axios.post(
    `/events/${eventId}/stages/${stageId}/breakout_sessions/bulk_update`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );

  store.dispatch(addBreakoutSession(data.breakoutSession));
  setSessionData(data);
  return data.breakoutSession;
}

export async function validateBreakoutCsv(file) {
  const { eventId, id: stageId } = stage();

  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axios.post(
    `/events/${eventId}/stages/${stageId}/breakout_sessions/bulk_create?validate_only=1`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );

  return data;
}

export function getUserHasCompletedBreakoutAvCheck() {
  const json = localStorage.getItem("userHasCompletedBreakoutAvCheck");
  if (json) {
    return JSON.parse(json) || {};
  }
  return {};
}

export function getUserHasCompletedBreakoutAvCheckForStage(stageId) {
  const obj = getUserHasCompletedBreakoutAvCheck();
  return obj[stageId] || false;
}

export function setUserHasCompletedBreakoutAvCheckForStage(
  stageId,
  value = true
) {
  const obj = getUserHasCompletedBreakoutAvCheck();
  obj[stageId] = value;
  localStorage.setItem("userHasCompletedBreakoutAvCheck", JSON.stringify(obj));
}

export async function subscribe() {
  const { id: breakoutRoomId } = store.getState().BreakoutRoom;
  return new Promise((resolve, reject) => {
    subscription = cable.subscriptions.create(
      { channel: CHANNEL, breakout_room_id: breakoutRoomId },
      {
        received: (_data) => {},
        initialized: resolve,
        connected: () => {
          if (pingInterval) {
            clearInterval(pingInterval);
          }

          pingInterval = setInterval(
            () => subscription?.perform("ping"),
            PING_INTERVAL
          );
        },
        disconnected: () => {
          if (pingInterval) {
            clearInterval(pingInterval);
          }
          WLog.log("warn", "cable.breakouts", `Disconnected: ${CHANNEL}`);
        },
        rejected: () => {
          WLog.log("warn", "cable.breakouts", `Rejected: ${CHANNEL}`);
          reject();
        },
      }
    );
  });
}

// unsubscribe from stage status changes
export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
}
