/**
 * @fileoverview The event subscription level, which determines which event streams (stage, green room) are subscribed to by
 * the current user
 */
const StageSubLevel = {
  ALL: 0,
  STAGE_ONLY: 1,
  NONE: 2,
};
export default StageSubLevel;
