/**
 * @fileoverview Manages internal state for local clients.
 *
 * THIS IS A TEMPORARY IMPLEMENTATION FOR THE 4.X MIGRATION. Since we're piggybacking
 * off of the app's existing state management, the only way to know if clients are
 * being destroyed are if the client.leave() method is being called. Because of that,
 * this is a dependency of each client. This is a pretty bad code smell, and moving
 * forward clients should be managed centrally within WelcomeAV & nowhere else in the
 * app.
 *
 * However, this class is not meant to serve as a foundation for a long-term solution
 * for managing this state––it's just a temporary workaround.
 */
export default class ClientManager {
  static _INSTANCE;

  static getInstance() {
    if (!this._INSTANCE) {
      this._INSTANCE = new ClientManager();
    }

    return this._INSTANCE;
  }

  constructor() {
    this.clientIds = new Set();
  }

  addClientId(clientId) {
    this.clientIds.add(clientId);
  }

  removeClientId(clientId) {
    this.clientIds.delete(clientId);
  }

  hasClientId(clientId) {
    return this.clientIds.has(clientId);
  }
}
