/**
 * @fileoverview Enum for Agora V3 events
 */
export default {
  STREAM_SUBSCRIBED: "stream-subscribed",
  LOCAL_STREAM_PUBLISHED: "stream-published",
  LOCAL_STREAM_UNPUBLISHED: "stream-unpublished",
  REMOTE_STREAM_PUBLISHED: "stream-added",
  REMOTE_STREAM_UNPUBLISHED: "stream-removed",
  PEER_LEFT: "peer-leave",
  MUTE_AUDIO: "mute-audio",
  MUTE_VIDEO: "mute-video",
  UNMUTE_AUDIO: "unmute-audio",
  UNMUTE_VIDEO: "unmute-video",
};
