/**
 * @fileoverview Custom instance of AudioWorkletProcessor that returns the
 * processed (filtered and muted when no volume) PCM data that we pass to our closed caption provider.
 *
 * Read more: https://developers.google.com/web/updates/2017/12/audio-worklet
 */
export default `
//js
class ClosedCaptionProcessor extends AudioWorkletProcessor {
  static get parameterDescriptors() {
    return [
      {
        name: "keepAlive",
        defaultValue: true,
      },
    ]
  }

  constructor() {
    super();
  }

  process(inputs, _outputs, parameters) {
    const input = inputs[0];

    if (input.length > 0) {
      if (!parameters.keepAlive[0]) {
        return false;
      }
     
      // Note: inputs are down-mixed to mono by default (only one channel)
      const samples = input[0];
      
      // TODO: Filter audio, and only postMessage when gain passes a certain threshold (in a later PR)

      this.port.postMessage(samples);
    }

    return true;
  }
};

registerProcessor("closedCaptionProcessor", ClosedCaptionProcessor);
`;
