import {
  SET_BREAKOUT_PRESENCE_FOR_USER,
  SET_BREAKOUT_PRESENCE_FOR_USERS,
} from "~~/redux/actionList";

export const setBreakoutPresenceForUser = (regId, isPresent) => ({
  type: SET_BREAKOUT_PRESENCE_FOR_USER,
  regId,
  isPresent,
});

export const setBreakoutPresenceForUsers = (onlineIds) => ({
  type: SET_BREAKOUT_PRESENCE_FOR_USERS,
  onlineIds,
});
