// NOTE: please leave this a simple object literal and
// do not add  conditional logic e.g. feature flags to this file
// we want to avoid potential confusion that has caused improper
// settings in the past.

const VIDEO_CONFIGURATION = {
  LOW_STAGE: {
    resolution: {
      width: 800,
      height: 450,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 100,
      max: 250,
    },
  },
  MEDIUM_STAGE: {
    resolution: {
      width: 800,
      height: 450,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 300,
      max: 800,
    },
  },
  HIGH_STAGE: {
    resolution: {
      width: 1280,
      height: 720,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 700,
      max: 1500,
    },
  },
  HIGHER_STAGE: {
    resolution: {
      width: 1920,
      height: 1080,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 1000,
      max: 4000,
    },
  },
  // Same as low breakout until we figure out what to do
  // 120p/15fps looks pretty terrible, but we can switch
  // if we have to
  VERY_LOW_BREAKOUT: {
    resolution: {
      width: 320,
      height: 180,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 100,
      max: 350,
    },
  },
  LOW_BREAKOUT: {
    resolution: {
      width: 320,
      height: 180,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 100,
      max: 350,
    },
  },
  HIGH_BREAKOUT: {
    resolution: {
      width: 640,
      height: 360,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 300,
      max: 800,
    },
  },
  LOW_SCREENSHARE: {
    resolution: {
      width: 320,
      height: 180,
    },
    frameRate: {
      min: 1,
      max: 1,
    },
    bitrate: {
      min: 50,
      max: 300,
    },
  },
  HIGH_SCREENSHARE: {
    resolution: {
      width: 1280,
      height: 720,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 500,
      max: 1500,
    },
  },
  HIGHER_SCREENSHARE: {
    resolution: {
      width: 1920,
      height: 1080,
    },
    frameRate: {
      min: 30,
      max: 30,
    },
    bitrate: {
      min: 500,
      max: 4000,
    },
  },
};

export default VIDEO_CONFIGURATION;
