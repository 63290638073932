/**
 * @fileoverview Helper methods for migrating video encoder configuration objects
 */

/**
 * Converts a 3.x video encoder configuration object to a 4.x object
 *
 * @param {Object} v3Config Agora 3.x video encoder configuration object
 * @returns {VideoEncoderConfiguration} Agora 4.x video encoder configuration object
 */
export function convertToV4Config(v3Config) {
  if (!v3Config) {
    return {};
  }

  return {
    width: v3Config.resolution.width,
    height: v3Config.resolution.height,
    frameRate: v3Config.frameRate.max,
    bitrateMin: v3Config.bitrate.min,
    bitrateMax: v3Config.bitrate.max,
  };
}

/**
 * Converts a 3.x video encoder object to a MediaTrackConstraints object.
 *
 * @param {Object} v3Config Agora 3.x video encoder configuration object
 * @returns {MediaTrackConstraints} Media track constraints
 */
export function convertToMediaTrackConstraints(v3Config) {
  if (!v3Config) {
    return {};
  }

  return {
    width: {
      min: v3Config.resolution.width * 0.8,
      ideal: v3Config.resolution.width,
      max: v3Config.resolution.width,
    },
    height: {
      min: v3Config.resolution.height * 0.8,
      ideal: v3Config.resolution.height,
      max: v3Config.resolution.height,
    },
    frameRate: {
      ideal: v3Config.frameRate.max,
    },
  };
}
