/**
 @fileoverview Some utility methods for streams
 */
import CLIENT_TYPE from "~~/constants/clientType";

// FIXME: This really doesn't belong here... move it out somehow.
// It's only being used to get the current user ID, so if we move that to local storage that would
// solve this problem
import { getTranslation } from "~~/i18n/i18n";
import store from "~~/redux/store";

/**
 * UserIDs have to be integers in order to work with Agora cloud recording.
 * To support multiple streamIDs per user depending on the client type, we will
 * bucket streamIDs into buckets of size BUCKET_SIZE with each bucket representing
 * a ClientType
 */
const BUCKET_SIZE = parseInt(window.CLIENT_ENV.AGORA_UID_BUCKET_SIZE, 10);

/**
 * Gets the bucket size, so that it can be mocked in unit tests.
 * TODO: Use this everywhere instead of declaring a global
 */
function getBucketSize() {
  return parseInt(window.CLIENT_ENV.AGORA_UID_BUCKET_SIZE, 10);
}

export function getStreamIdFromUserId(userId, clientType) {
  // Fallback if there's no user id. Happens if there's no user logged
  // in ie in the compatibility check
  const uid = userId || 0;
  const bucketSize = getBucketSize();
  return uid + clientType * bucketSize;
}

export function getRegIdFromStreamId(streamId) {
  return streamId % BUCKET_SIZE;
}

export function getClientTypeFromStreamId(streamId) {
  return Math.floor(streamId / BUCKET_SIZE);
}

/**
 * Gets the stream config matching Agora's StreamSpec object for creating
 * streams.
 *
 * NOTE: Does not include the cameraId and microphoneId from the stream,
 * because those properties will be inaccurate if you replace the tracks.
 * We could get those from track.getSettings(), however that's not supported
 * on older browsers.
 */
export function getStreamConfig(stream) {
  if (!stream || !stream.connectionSpec) {
    return {};
  }

  return {
    audio: stream.audio,
    video: stream.video,
    screen: stream.screen,
    screenAudio: stream.screenAudio,
    mirror: stream.mirror,
  };
}

export function isAudioMuted(stream) {
  return stream?.isAudioMuted();
}

export function isVideoMuted(stream) {
  return stream?.isVideoMuted();
}

export function sortStreams(stream1, stream2) {
  const regId1 = getRegIdFromStreamId(stream1.getId());
  const regId2 = getRegIdFromStreamId(stream2.getId());

  // Order by user
  if (regId1 < regId2) {
    return -1;
  }
  if (regId1 > regId2) {
    return 1;
  }

  // Order by stream ID as a fallback. This will basically order by client type
  return stream1.getId() < stream2.getId();
}

export function sortStreamsMeFirst(stream1, stream2) {
  const { registration: currentRegistration } = store.getState().User;

  const regId1 = getRegIdFromStreamId(stream1.getId());
  const regId2 = getRegIdFromStreamId(stream2.getId());

  if (regId1 === currentRegistration.id) {
    return -1;
  }
  if (regId2 === currentRegistration.id) {
    return 1;
  }

  return sortStreams(stream1, stream2);
}

export function isScreenshareId(streamId) {
  const clientType = getClientTypeFromStreamId(streamId);
  return (
    clientType === CLIENT_TYPE.SCREEN ||
    clientType === CLIENT_TYPE.SECONDARY_SCREEN
  );
}

export function isScreenshare(stream) {
  return isScreenshareId(stream.getId());
}

export function isBreakout(streamId) {
  const clientType = getClientTypeFromStreamId(streamId);
  return clientType === CLIENT_TYPE.SECONDARY;
}

export function fullStreamName(stream, regs) {
  const regId = getRegIdFromStreamId(stream.getId());
  const reg = regs[regId];
  if (!reg) {
    return "";
  }
  return `${reg.firstName} ${reg.lastName}`;
}

export function getLabelForStreamId(streamId, reg, isLastNameInitial = false) {
  if (!reg || !streamId) {
    return "";
  }
  const { registration: currentReg } = store.getState().User;
  const lastName =
    reg.lastName && isLastNameInitial
      ? `${reg.lastName.substring(0, 1)}.`
      : reg.lastName || "";

  const currUserLabelTranslated = getTranslation("video_call", "you", "You");
  const label =
    reg.id === currentReg.id
      ? currUserLabelTranslated
      : `${reg.firstName} ${lastName}`;

  if (isScreenshareId(streamId)) {
    return `🖥️ ${label}`;
  }
  return label;
}

export function getLabelForStreamWithRegistrations(
  stream,
  registrations,
  isLastNameInitial = false
) {
  const regId = getRegIdFromStreamId(stream.getId());
  const reg = registrations[regId];
  if (!reg) {
    return null;
  }
  return getLabelForStreamId(stream.getId(), reg, isLastNameInitial);
}
