import {
  ADD_BREAKOUT_SESSION,
  REMOVE_SINGLE_BREAKOUT_SESSION,
  SET_BREAKOUT_SESSIONS,
  UPDATE_SINGLE_BREAKOUT_SESSION,
} from "~~/redux/actionList";

export const setBreakoutSessions = (breakoutSessions) => ({
  type: SET_BREAKOUT_SESSIONS,
  breakoutSessions,
});

export const addBreakoutSession = (breakoutSession) => ({
  type: ADD_BREAKOUT_SESSION,
  breakoutSession,
});

export const updateBreakoutSession = (breakoutSession) => ({
  type: UPDATE_SINGLE_BREAKOUT_SESSION,
  breakoutSession,
});

export const removeSingleBreakoutSession = (sessionId) => ({
  type: REMOVE_SINGLE_BREAKOUT_SESSION,
  sessionId,
});
