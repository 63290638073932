/**
 * @fileoverview The API that the WelcomeAV library exposes to the user. THIS API
 * IS TEMPORARY AND IN FUTURE ITERATIONS THIS FUNCTIONALITY WILL NOT BE EXPOSED
 * TO THE USER.
 *
 * Initial implementation based on this doc:
 * https://www.notion.so/experiencewelcome/Agora-SDK-4-x-Migration-ce41f915527e4b5aa38215fd0e2c69d4
 *
 * The end-goal is a context-based library containing all of our video layer, as described here:
 * https://www.notion.so/experiencewelcome/WIP-WelcomeAV-Welcome-Video-Library-86437c4e653544b38264884119d89730
 */
import Provider from "~~/welcomeav/WelcomeAVProvider";
import * as AudioContainer from "~~/welcomeav/audio/AudioContainer";
import * as DeviceService from "~~/welcomeav/ext/deviceService";
import * as StreamingContainer from "~~/welcomeav/streaming/StreamingContainer";
import * as ClientService from "~~/welcomeav/streaming/agoraMiddleware/agoraRcClientFactory";
import * as StreamingService from "~~/welcomeav/streaming/agoraMiddleware/agoraRcStreamFactory";

export default {
  createClient: ClientService.createClient,
  createStream: StreamingService.createStream,
  getDevices: DeviceService.getDevices,
  Provider,
  StreamingContext: StreamingContainer.Context,
  AudioContext: AudioContainer.Context,
};

export { default as RCNetworkQuality } from "~~/welcomeav/streaming/models/RCNetworkQuality";
