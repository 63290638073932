import {
  ADD_BREAKOUT_STREAM,
  CLEAR_BREAKOUT_STREAMS,
  REMOVE_BREAKOUT_STREAM,
} from "~~/redux/actionList";

export const addVideoCallStream = (stream) => ({
  type: ADD_BREAKOUT_STREAM,
  stream,
});

export const removeVideoCallStreamById = (streamId) => ({
  type: REMOVE_BREAKOUT_STREAM,
  streamId,
});

export const clearVideoCallStreams = () => ({
  type: CLEAR_BREAKOUT_STREAMS,
});
