/**
 * @fileoverview WelcomeAV's client class. As part of the Agora 4.x migration,
 * this is temporarily equivalent to Agora's 3.x client object
 */
import EventEmitter from "events";
import * as FeatureFlags from "~~/utils/featureFlags";
import * as AgoraMigrationEventHelper from "~~/welcomeav/streaming/agoraMiddleware/agoraMigrationEventHelper";
import ClientManager from "~~/welcomeav/streaming/agoraMiddleware/clientManager";
import * as EncoderMigrationHelper from "~~/welcomeav/streaming/agoraMiddleware/encoderMigrationHelper";
import * as NetworkQualityHelper from "~~/welcomeav/streaming/agoraMiddleware/networkQualityHelper";
import * as StatsMigrationHelper from "~~/welcomeav/streaming/agoraMiddleware/statsMigrationHelper";
import SubscriptionStateManager from "~~/welcomeav/streaming/agoraMiddleware/subscriptionStateManager";
import AgoraV3Event from "~~/welcomeav/streaming/models/agoraV3Event";
import WLog from "~~/wlog";

const RTMP_STREAM_KEY = 666;

export default class RCClient {
  constructor(
    clientId,
    agoraClient,
    channel,
    channelMode,
    eventEmitter = new EventEmitter(),
    subscriptionManager = new SubscriptionStateManager()
  ) {
    this.clientId = clientId;
    this.agoraClient = agoraClient;
    this.channel = channel;
    this.channelMode = channelMode;
    this.eventEmitter = eventEmitter;
    this.subscriptionManager = subscriptionManager;

    AgoraMigrationEventHelper.bindV4EventsToEmitter(
      subscriptionManager,
      agoraClient,
      eventEmitter
    );

    this.agoraClient.on("exception", (err) => {
      WLog.log(
        "warn",
        "welcomeav",
        "[RCClient] Agora internal client exception",
        err
      );
    });
  }

  /**
   * Get the ID of this client
   *
   * @returns {Number | String} the ID of the client
   */
  getId() {
    return this.clientId;
  }

  /**
   * Subscribe to an event.
   *
   * @param {string} eventName Name of the event
   * @param {*} listener Callback listener for the event
   */
  on(eventName, listener) {
    if (eventName === "network-quality") {
      this.agoraClient.on(eventName, ({ uplinkNetworkQuality }) => {
        const quality =
          NetworkQualityHelper.getRCNetworkQuality(uplinkNetworkQuality);
        listener(quality);
      });
    }

    if (eventName === "exception") {
      this.agoraClient.on(eventName, listener);
    }

    // Internal emitter is for the 4.x migration only.
    this.eventEmitter.on(eventName, listener);
  }

  getRemoteStreamNetworkQualities() {
    const qualities = this.agoraClient.getRemoteNetworkQuality();
    return Object.keys(qualities).reduce((obj, streamId) => {
      /* eslint-disable-next-line no-param-reassign */
      obj[streamId] = NetworkQualityHelper.getRCNetworkQuality(
        qualities[streamId].uplinkNetworkQuality
      );
      return obj;
    }, {});
  }

  /**
   * INTERNAL USE ONLY. Emits an event
   *
   * @param {AgoraV4Event} eventName The event to emit
   */
  emit(eventName) {
    this.eventEmitter.emit(eventName);
  }

  /**
   * Leave the channel
   *
   * @param {Function} onSuccess Success callback. Passes no parameters
   * @param {Function} onFailure Failure callback. Passes an error
   */
  async leave() {
    await this.agoraClient.leave();
    ClientManager.getInstance().removeClientId(this.clientId);
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Client ${this.clientId} left channel ${this.channel}`
    );
  }

  /**
   * Subscribe to a stream
   *
   * @param {RCStream} stream Remote stream to subscribe to
   */
  async subscribe(stream) {
    if (stream.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Attempt to subscribe to local stream"
      );
      return;
    }

    this.subscriptionManager.addSubscribedUid(stream.getId());

    // If we're already subscribed to both audio and video...
    // then early return. Prevents infinite loops when subscribing to RTMP
    // stream
    // TODO[@phil]: Need better state management for whether or not a stream
    // has audio/video and stuff...
    if (stream.getAgoraAudioTrack() && stream.getAgoraVideoTrack()) {
      return;
    }

    // If we've subscribed to any of the RTMP's tracks, we don't want to
    // attempt to re-subscribe to the tracks as they may not exist (i.e. a video without sound)
    // and we'll get stuck in a loop attempting to subscribe.
    if (
      stream.getId() === RTMP_STREAM_KEY &&
      (stream.getAgoraVideoTrack() || stream.getAgoraAudioTrack())
    ) {
      return;
    }

    const subscriptionPromises = [];
    const subscriptionTypes = [];
    if (stream.shouldSubscribeToVideo()) {
      subscriptionPromises.push(
        this.agoraClient.subscribe(stream.getAgoraRemoteUser(), "video")
      );
      subscriptionTypes.push("video");
    }
    if (stream.shouldSubscribeToAudio()) {
      subscriptionPromises.push(
        this.agoraClient.subscribe(stream.getAgoraRemoteUser(), "audio")
      );
      subscriptionTypes.push("audio");
    }

    if (subscriptionPromises.length > 0) {
      await Promise.allSettled(subscriptionPromises);
      WLog.log(
        "debug",
        "welcomeav.streaming",
        `Successful subscribe to remote stream ${stream.getId()}: ${JSON.stringify(
          subscriptionTypes
        )}`
      );
      this.eventEmitter.emit(AgoraV3Event.STREAM_SUBSCRIBED, { stream });
    } else {
      // If we have nothing to subscribe to, the remote user may have both a/v
      // muted. Which is fine. Emit successful subscribe
      this.eventEmitter.emit(AgoraV3Event.STREAM_SUBSCRIBED, { stream });
    }
  }

  /**
   * Unsubscribe from a stream
   *
   * @param {RCStream} stream Stream to unsubscribe from. Must be remote
   */
  async unsubscribe(stream) {
    if (stream.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Attempt to unsubscribe from local stream"
      );
      return Promise.resolve();
    }

    this.subscriptionManager.removeSubscribedUid(stream.getId());
    return this.agoraClient.unsubscribe(stream.getAgoraRemoteUser());
  }

  /**
   * Publish a stream
   *
   * @param {RCStream} stream Stream to publish. Must be a local stream.
   */
  async publish(stream) {
    if (!stream.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Attempt to publish remote stream"
      );
      return;
    }

    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Publishing tracks for client ${this.getId()}`
    );

    if (this.channelMode === "live") {
      await this.agoraClient.setClientRole("host");
      WLog.log(
        "debug",
        "welcomeav.streaming",
        "Client Role set to host so ClientRoleOptions.level no longer applies"
      );
    }

    const tracks = stream.getAgoraTracks();
    await this.agoraClient.publish(tracks);

    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Published tracks for client ${this.getId()}`
    );
    this.eventEmitter.emit(AgoraV3Event.LOCAL_STREAM_PUBLISHED, { stream });
  }

  /**
   * Unpublish a stream
   *
   * @param {RCStream} stream Stream to unpublish
   * @returns {Promise<Void>} A promise, to help with the migration.
   */
  async unpublish(stream) {
    if (!stream.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Attempt to unpublish remote stream"
      );
      return;
    }

    const tracks = stream.getAgoraTracks();

    const { releaseLowLatencyMode } = FeatureFlags.getFeatureFlags();

    // Known issue with Agora (v4.6.3): if we try to unpublish both tracks at
    // the same time, and the camera track is disabled, it won't successfully
    // unpublish.
    await Promise.allSettled(tracks.map((t) => this.agoraClient.unpublish(t)));
    this.eventEmitter.emit(AgoraV3Event.LOCAL_STREAM_UNPUBLISHED, {
      stream,
    });

    const clientRoleOptionsLevel = releaseLowLatencyMode
      ? AgoraRTC.AudienceLatencyLevelType.AUDIENCE_LEVEL_LOW_LATENCY
      : AgoraRTC.AudienceLatencyLevelType.AUDIENCE_LEVEL_ULTRA_LOW_LATENCY;

    if (this.channelMode === "live") {
      WLog.assertionFailure(
        "welcomeav.streaming",
        `Setting clientRole = audience and clientRoleOptions.level = ${clientRoleOptionsLevel}`
      );
      await this.agoraClient.setClientRole("audience", {
        level: clientRoleOptionsLevel,
      });
    }
  }

  async publishAndAddVideoTrackToStream(stream, config, videoConfiguration) {
    if (stream.getAgoraVideoTrack()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Stream already has a video track"
      );
    }
    const encoderConfig =
      EncoderMigrationHelper.convertToV4Config(videoConfiguration);
    const videoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: config.cameraId,
      encoderConfig,
      optimizationMode: "motion",
    });
    await this.agoraClient.publish(videoTrack);
    // eslint-disable-next-line no-param-reassign
    stream.agoraVideoTrack = videoTrack;
    // eslint-disable-next-line no-param-reassign
    stream.isVideoTrackEnabled = true;
  }

  async unpublishAndRemoveVideoTrackFromStream(stream) {
    if (!stream.getAgoraVideoTrack()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "No video track attached to stream"
      );
      return;
    }
    await this.agoraClient.unpublish(stream.getAgoraVideoTrack());
    stream.agoraVideoTrack.stop();
    stream.agoraVideoTrack.setEnabled(false);
    // eslint-disable-next-line no-param-reassign
    stream.agoraVideoTrack = null;
    // eslint-disable-next-line no-param-reassign
    stream.isVideoTrackEnabled = false;
  }

  /**
   * Gets stats of remote video
   *
   * @param {Function} statsCallback Passes  a map of uid => 3.x stats object
   */
  getRemoteVideoStats(statsCallback) {
    const stats = this.agoraClient.getRemoteVideoStats();
    statsCallback(StatsMigrationHelper.convertRemoteVideoStatsMap(stats));
  }

  /**
   * Gets stats of local video
   *
   * @param {Function} statsCallback Passes  a map of uid => 3.x stats object
   */
  getLocalVideoStats(statsCallback) {
    const stats = this.agoraClient.getLocalVideoStats();
    // this is the best way I've found to see if virtual bg is enabled.
    // no way to tell what type (blur, image, color) though.
    stats["virtualBackgroundsEnabled"] =
      this.agoraClient.localTracks &&
      this.agoraClient.localTracks[0]?.processorDestination?._source?.name ===
        "VirtualBackgroundProcessor" &&
      this.agoraClient.localTracks[0]?.processorDestination?._source?.enabled;

    statsCallback({
      [this.getId()]: StatsMigrationHelper.convertLocalVideoStats(stats),
    });
  }

  /**
   * Gets stats of remote audio
   *
   * @param {Function} statsCallback Passes  a map of uid => 3.x stats object
   */
  getRemoteAudioStats(statsCallback) {
    const stats = this.agoraClient.getRemoteAudioStats();
    statsCallback(StatsMigrationHelper.convertRemoteAudioStatsMap(stats));
  }

  /**
   * Gets stats of local audio
   *
   * @param {Function} statsCallback Passes  a map of uid => 3.x stats object
   */
  getLocalAudioStats(statsCallback) {
    const stats = this.agoraClient.getLocalAudioStats();
    statsCallback({
      [this.getId()]: StatsMigrationHelper.convertLocalAudioStats(stats),
    });
  }
}
