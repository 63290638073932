/**
 * @fileoverview Helper methods for video/audio stats for the Agora 4.x migration
 */
/* eslint-disable no-param-reassign */

/**
 * @param {RemoteVideoTrackStats} stats 4.x remote video stats object
 * @returns {Object} Agora 3.x remote video stats object
 */
export function convertRemoteVideoStats(stats) {
  if (!stats) {
    return {};
  }

  return {
    RecvBitrate: Math.floor(stats.receiveBitrate / 1000),
    videoReceivePacketsLost: stats.receivePacketsLost,
    videoReceiveResolutionWidth: stats.receiveResolutionWidth,
    videoReceiveResolutionHeight: stats.receiveResolutionHeight,
    videoReceiveFrameRate: stats.receiveFrameRate,
    accessDelay: stats.end2EndDelay,
    packetLossRate: isNaN(stats.packetLossRate) ? 0 : stats.packetLossRate,
  };
}

/**
 * @param {uid => RemoteVideoTrackStats} statsMap Mapping of uid => remote video stats
 * @returns {uid => Object} Mapping of uid => 3.x stats object
 */
export function convertRemoteVideoStatsMap(statsMap) {
  return Object.keys(statsMap).reduce((stats, uid) => {
    stats[uid] = convertRemoteVideoStats(statsMap[uid]);
    return stats;
  }, {});
}

/**
 * @param {RemoteAudioTrackStats} stats 4.x remote audio stats object
 * @returns {Object} Agora 3.x stats object
 */
export function convertRemoteAudioStats(stats) {
  if (!stats) {
    return {};
  }

  return {
    RecvBitrate: Math.floor(stats.receiveBitrate / 1000),
    audioReceivePacketsLost: stats.receivePacketsLost,
    accessDelay: stats.end2EndDelay,
    packetLossRate: isNaN(stats.packetLossRate) ? 0 : stats.packetLossRate,
  };
}

/**
 * @param {uid => RemoteAudioTrackStats} statsMap Mapping of uid => remote audio stats
 * @returns {uid => Object} Mapping of uid => 3.x stats object
 */
export function convertRemoteAudioStatsMap(statsMap) {
  return Object.keys(statsMap).reduce((stats, uid) => {
    stats[uid] = convertRemoteAudioStats(statsMap[uid]);
    return stats;
  }, {});
}

/**
 * @param {LocalAudioTrackStats} stats 4.x remote video stats object
 * @returns {Object} Agora 3.x stats object
 */
export function convertLocalVideoStats(stats) {
  if (!stats) {
    return {};
  }

  return {
    SendBitrate: Math.floor(stats.sendBitrate / 1000),
    videoSendPacketsLost: stats.sendPacketsLost,
    videoSendResolutionWidth: stats.sendResolutionWidth,
    videoSendResolutionHeight: stats.sendResolutionHeight,
    videoSendFrameRate: stats.sendFrameRate,
    packetLossRate:
      stats.sendPackets === 0 ? 0 : stats.sendPacketsLost / stats.sendPackets,
    accessDelay: -1,
    virtualBackgroundsEnabled: stats.virtualBackgroundsEnabled,
  };
}

/**
 * @param {LocalAudioTrackStats} stats 4.x remote audio stats object
 * @returns {Object} Agora 3.x stats object
 */
export function convertLocalAudioStats(stats) {
  if (!stats) {
    return {};
  }

  return {
    SendBitrate: Math.floor(stats.sendBitrate / 1000),
    audioSendPacketsLost: stats.sendPacketsLost,
    packetLossRate:
      stats.sendPackets === 0 ? 0 : stats.sendPacketsLost / stats.sendPackets,
    accessDelay: -1,
  };
}
