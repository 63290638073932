import {
  REMOVE_BREAKOUT_SESSION,
  SET_BREAKOUT_SESSION,
  UPDATE_BREAKOUT_SESSION,
} from "~~/redux/actionList";

export const setBreakoutSession = (breakoutSession) => ({
  type: SET_BREAKOUT_SESSION,
  breakoutSession,
});

export const removeBreakoutSession = () => ({
  type: REMOVE_BREAKOUT_SESSION,
});

export const updateBreakoutSession = (data) => ({
  type: UPDATE_BREAKOUT_SESSION,
  data,
});
