/**
 * @fileoverview Manages state of streams that have been subscribed to.
 * Temporary way to make sure attendees don't subscribe to green room
 * streams.
 */
export default class SubscriptionStateManager {
  constructor() {
    this.subscribedUids = new Set();
  }

  addSubscribedUid(uid) {
    this.subscribedUids.add(uid);
  }

  removeSubscribedUid(uid) {
    this.subscribedUids.delete(uid);
  }

  isSubscribed(uid) {
    return this.subscribedUids.has(uid);
  }
}
