import {
  ARCHIVE_POLL,
  CREATE_POLL,
  DELETE_POLL,
  SEARCH_FOR_POLLS,
  SET_EDITING_POLL,
  SET_MOST_RECENTLY_OPENED_POLL,
  SET_POLLS,
  START_POLL_SEARCH,
  STOP_POLL_SEARCH,
  UPDATE_POLL,
  VOTE_FOR_POLL,
} from "~~/redux/actionList";

export const archivePollFromChannel = (pollHashid) => ({
  payload: { pollHashid },
  type: ARCHIVE_POLL,
});

export const createPollFromChannel = (poll) => ({
  payload: { poll },
  type: CREATE_POLL,
});

export const deletePollFromChannel = (pollHashid) => ({
  payload: { pollHashid },
  type: DELETE_POLL,
});

export const updatePollFromChannel = (poll) => ({
  payload: { poll },
  type: UPDATE_POLL,
});

export const setEditingPoll = (pollHashid) => ({
  type: SET_EDITING_POLL,
  payload: { pollHashid },
});

export const setPolls = (polls) => ({ type: SET_POLLS, payload: { polls } });

export const voteForPoll = (poll) => ({
  type: VOTE_FOR_POLL,
  payload: { poll },
});

export const searchForPolls = (searchText) => ({
  type: SEARCH_FOR_POLLS,
  payload: { searchText },
});

export const startSearching = () => ({ type: START_POLL_SEARCH });

export const stopSearching = () => ({ type: STOP_POLL_SEARCH });

export const setMostRecentlyOpenedPoll = (pollHashid) => ({
  type: SET_MOST_RECENTLY_OPENED_POLL,
  payload: { pollHashid },
});
