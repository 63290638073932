/**
 * @fileoverview Helper methods for the AudioContainer
 */
import WLog from "~~/wlog";

/**
 * @param {AudioProcessingManager} processingManager The AudioProcessingManager to listen
 * for volume on
 * @param {Function} volumeListener A listener for volumes, which range from 0-100
 */
export async function processVolume(processingManager, volumeListener) {
  try {
    await processingManager.addProcessor("volumeProcessor", (event) => {
      // The volume levels should be similar whether we're using audioWorklet or
      // scriptProcessor. AudioWorklet values should be normalized.
      const normalizationScalar = 1.5;
      const volume = Math.min(100, event.data * normalizationScalar);
      volumeListener(volume);
    });
  } catch (error) {
    WLog.assertionFailure(
      "welcomeav.audio",
      `Failed to use audio worklet for volume processing: ${error.message}`
    );
    const reportRate = 5; // Only fire callback every x reports
    let reportCount = 0; // Track total # reports
    const [analyzerNode, scriptProcessorNode] =
      processingManager.getAnalyzerAndScriptProcessor();
    scriptProcessorNode.onaudioprocess = () => {
      reportCount++;
      if (reportCount % reportRate !== 0) {
        return;
      }

      const data = new Uint8Array(analyzerNode.frequencyBinCount);
      analyzerNode.getByteFrequencyData(data);

      let sum = 0;
      for (let i = 0; i < data.length; i++) {
        sum += data[i];
      }

      const avg = sum / data.length;

      // Volumes are in range of 0-255; normalize into range of 0-100
      const volume = Math.min((avg / 255) * 100, 100);
      volumeListener(volume);
    };
  }
}
