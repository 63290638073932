/* eslint-disable import/prefer-default-export */
import EventEmitter from "events";
import * as actions from "~~/redux/actions/stage";
import store from "~~/redux/store";
import {
  COOLDOWN_IN_SECONDS,
  getPublicChannelForStage,
} from "~~/services/chatService";
import CustomCable, { setRecorder } from "~~/utils/CustomCable";
import WLog from "~~/wlog";

// Constants
const CHANNEL = "StageStatusChannel";

const MSG_TYPE = {
  SNAPSHOT: "snapshot",
  RECORDING_STARTED: "recordingStarted", // Agora web recording only
  RECORDING_STOPPED: "recordingStopped", // Agora web recording only
};
const events = ["recordingStarted", "recordingStopped"];
const emitter = new EventEmitter();
let subscription = null;
export function on(evt, callback) {
  if (events.indexOf(evt) !== -1) {
    emitter.on(evt, callback);
  } else {
    WLog.assertionFailure(
      "stage.stagestatus",
      `No event ${evt} on stage status`
    );
  }
}

export function off(evt, callback) {
  emitter.off(evt, callback);
}

// set the entire stage
export function setStage(stageData) {
  store.dispatch(actions.setStage(stageData));
}

// Set the stage status for frontend only
export function setStageStatus(status) {
  store.dispatch(actions.setStageStatus(status));
}

export function startRecording() {
  subscription?.perform("start_recording");
}

export function confirmStartRecording(ec2HostName) {
  subscription?.perform("confirm_start_recording", {
    ec2_host_name: ec2HostName,
  });
}

export function confirmStartRecordingContainer() {
  subscription?.perform("confirm_start_recording_container");
}

export function stopRecording() {
  subscription?.perform("stop_recording");
}

// Update an attribute of the stage live for everyone at the event (producers only)
export function realtimeUpdateStage(params) {
  subscription?.perform("update", params);
}

export function togglePublicChatEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ publicChatEnabled: !stage.publicChatEnabled });
}

// This function makes sure that the field stage.publicChatSlowModeEnabled is kept in
// sync with Stream Chat's channel. Don't change these things independently of each other!
export async function togglePublicChatSlowModeEnabled() {
  const stage = store.getState().Stage;

  const publicChannel = await getPublicChannelForStage(stage);

  const { channel: publicChannelState } = await publicChannel.watch();
  const isSlowModeEnabled = !!(
    publicChannelState.cooldown && publicChannelState.cooldown > 0
  );

  if (isSlowModeEnabled) {
    await publicChannel.disableSlowMode();
  } else {
    await publicChannel.enableSlowMode(COOLDOWN_IN_SECONDS);
  }

  realtimeUpdateStage({
    publicChatSlowModeEnabled: !isSlowModeEnabled,
  });
}

export function toggleQnaEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ qnaEnabled: !stage.qnaEnabled });
}

export function toggleQnaModerationEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ qnaModerationEnabled: !stage.qnaModerationEnabled });
}

export function toggleQnaHiddenNamesEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    qnaAllHiddenNamesEnabled: false,
    qnaHiddenNamesEnabled: !stage.qnaHiddenNamesEnabled,
  });
}

export function toggleQnaAllHiddenNamesEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    qnaHiddenNamesEnabled: false,
    qnaAllHiddenNamesEnabled: !stage.qnaAllHiddenNamesEnabled,
  });
}

export function toggleRaiseHandEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ raiseHandEnabled: !stage.raiseHandEnabled });
}

export function togglePeopleListEnabled() {
  const stage = store.getState().Stage;
  const updatedValue = !stage.peopleListEnabled;

  const params = { peopleListEnabled: !stage.peopleListEnabled };
  // If disabling people list, also disable DMs
  if (!updatedValue) {
    params.directMessagesEnabled = false;
  }
  realtimeUpdateStage(params);
}

export function togglePeopleListShowCountEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    peopleListShowCountEnabled: !stage.peopleListShowCountEnabled,
  });
}

export function togglePollsShowVoteCountEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    pollsShowVoteCountEnabled: !stage.pollsShowVoteCountEnabled,
  });
}

export function toggleDirectMessagesEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ directMessagesEnabled: !stage.directMessagesEnabled });
}

export function toggleVideoCallingEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ videoCallEnabled: !stage.videoCallEnabled });
}

export function toggleClosedCaptions() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ closedCaptionsEnabled: !stage.closedCaptionsEnabled });
}

export function toggleLowerThirdEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ lowerThirdEnabled: !stage.lowerThirdEnabled });
}

export function toggleSpeakerBubblesEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ speakerBubblesEnabled: !stage.speakerBubblesEnabled });
}

export function toggleSpeakerNamesEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ speakerNamesEnabled: !stage.speakerNamesEnabled });
}

export function toggleNameTagsAutoDismissEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    nameTagsAutoDismiss: !stage.nameTagsAutoDismiss,
  });
}

export function toggleOverlayFadeEnabled() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ overlayFadeEnabled: !stage.overlayFadeEnabled });
}

export function toggleAssetPreviewPopper() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({
    assetPreviewPopperEnabled: !stage.assetPreviewPopperEnabled,
  });
}

export function toggleAgendaVisible() {
  const stage = store.getState().Stage;
  realtimeUpdateStage({ agendaVisibleEnabled: !stage.agendaVisibleEnabled });
}

// Change the stage status on our backend
export function changeStageStatus(
  status,
  kickAttendeesOnClose,
  sendAnalyticsEmail = false
) {
  realtimeUpdateStage({ status, kickAttendeesOnClose, sendAnalyticsEmail });
}

// subscribe to stage status changes
export async function subscribe(stageId, signedStageId, eventId) {
  subscription = new CustomCable(
    {
      channel: CHANNEL,
      stage_id: stageId,
      signed_stage_id: signedStageId,
      event_id: eventId,
    },
    {
      received: (data) => {
        const currentReg = store.getState().User.registration;
        switch (data.messageType) {
          case MSG_TYPE.SNAPSHOT:
            setStage(data.payload);
            break;
          case MSG_TYPE.RECORDING_STARTED:
            if (currentReg.registrationType !== "staff") {
              break;
            }
            emitter.emit("recordingStarted");
            break;
          case MSG_TYPE.RECORDING_STOPPED:
            if (currentReg.registrationType !== "staff") {
              break;
            }
            emitter.emit("recordingStopped");
            break;
          default:
            break;
        }
      },
    },
    /* displayErrorMessages */ true
  );
  await subscription.subscribe();
}

export async function subscribeRecorder(stageId, signedStageId, eventId) {
  setRecorder();
  subscription = new CustomCable(
    {
      channel: CHANNEL,
      stage_id: stageId,
      signed_stage_id: signedStageId,
      event_id: eventId,
    },
    {
      received: (data) => {
        switch (data.messageType) {
          case MSG_TYPE.SNAPSHOT:
            setStage(data.payload);
            break;
          default:
            break;
        }
      },
    }
  );
  await subscription.subscribe();
}

// unsubscribe from stage status changes
export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription?.unsubscribe();
}
