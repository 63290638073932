/**
 * @fileoverview Middleware for Agora 4.x tracks and streaming APIs related to them
 */
import * as EncoderMigrationHelper from "~~/welcomeav/streaming/agoraMiddleware/encoderMigrationHelper";
import RCStream from "~~/welcomeav/streaming/models/rcStream";
import WLog from "~~/wlog";
import VIDEO_CONFIGURATION from "~~/constants/videoConfiguration";

/**
 * Create a screenshare stream
 *
 * @param {RCClient} client Client ID for the stream
 * @param {Boolean} screenAudioEnabled Whether or not to enable screen audio
 * @param {Object} v3EncoderConfig Agora 3.x encoder config
 * @returns {Promise<RCStream>} WelcomeAV stream object
 */
async function _createScreenshareStream(
  client,
  screenAudioEnabled,
  v3EncoderConfig
) {
  /**
   * We create the screenshare stream at the highest resolution, and
   * adjust its resolution from there. We can lower the resolution of
   * of high-res screenshare, but trying the reverse will result in an
   * OverconstrainedError (using the applyConstraints API).
   *
   * Right now these are hard-coded to match the product for simplicity,
   * but later on these max values should come from the product side
   * in some way
   */
  const MAX_HEIGHT = 1080;
  const MAX_WIDTH = 1920;
  const MAX_FRAME_RATE = 30;

  let videoTrack;
  let audioTrack;

  const encoderConfig =
    EncoderMigrationHelper.convertToV4Config(v3EncoderConfig);
  const trackOrTracks = await AgoraRTC.createScreenVideoTrack(
    {
      // Defaults
      width: MAX_WIDTH,
      height: MAX_HEIGHT,
      frameRate: MAX_FRAME_RATE,
      bitrateMin: 50,
      bitrateMax: 1800,
      optimizationMode: "detail",
      ...encoderConfig,
    },
    screenAudioEnabled ? "auto" : "disable"
  );

  if (Array.isArray(trackOrTracks)) {
    [videoTrack, audioTrack] = trackOrTracks;
  } else {
    videoTrack = trackOrTracks;
  }

  /**
   * To manually test that initial encoder configurations are being
   * set correctly, you must do all of the following:
   *
   * 1. With a "portrait-mode" screenshare, publish directly to stage and then
   * move to green room, and then back to stage. Also do this in reverse; publish
   * directly to green room and move to stage, then back to green room.
   * 2. Do the same for a "landscape-mode" screenshare
   */

  // If resolution needs to be made smaller, make it smaller using
  // applyConstraints API. Agora setEncoderConfig API doesn't work
  if (
    encoderConfig.height <
      videoTrack.getMediaStreamTrack().getSettings().height ||
    encoderConfig.width < videoTrack.getMediaStreamTrack().getSettings().width
  ) {
    videoTrack
      .getMediaStreamTrack()
      .applyConstraints(
        EncoderMigrationHelper.convertToMediaTrackConstraints(v3EncoderConfig)
      );
  }

  return RCStream.createLocalStream(client, videoTrack, audioTrack);
}

/**
 * Creates a camera & microphone stream
 *
 * @param {RCClient} client Client ID for the stream
 * @param {String?} camera Camera ID
 * @param {String?} microphone Microphone ID
 * @param {VideoEncoderConfiguration} encoderConfig Agora 4.x encoder config
 * @returns {Promise<RCStream>} WelcomeAV stream object
 */
async function _createHardwareStream(
  client,
  camera,
  microphone,
  v3EncoderConfig
) {
  const encoderConfig =
    EncoderMigrationHelper.convertToV4Config(v3EncoderConfig);

  const { audioTrack, videoTrack } = await createAgoraAudioVideoTracks({
    cameraId: camera,
    microphoneId: microphone,
    encoderConfig,
  });

  return RCStream.createLocalStream(client, videoTrack, audioTrack);
}

async function _createVideoFileStream(client, videoUrl, v3EncoderConfig) {
  // TODO: These video elements need to be cleaned up
  const videoEl = document.createElement("video");
  videoEl.src = videoUrl;
  videoEl.volume = 0.00001;
  videoEl.crossOrigin = "anonymous";
  videoEl.autoplay = true;
  videoEl.loop = true;

  const encoderConfig =
    EncoderMigrationHelper.convertToV4Config(v3EncoderConfig);

  return new Promise((resolve, reject) => {
    videoEl.onloadeddata = async () => {
      const mediaStream = videoEl.captureStream();

      WLog.assert(
        mediaStream.getAudioTracks().length > 0,
        "welcomeav.streaming",
        "No audio tracks in video file stream source",
        videoUrl
      );
      WLog.assert(
        mediaStream.getVideoTracks().length > 0,
        "welcomeav.streaming",
        "No video tracks in video file stream source",
        videoUrl
      );

      const videoTrack = await AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: mediaStream.getVideoTracks()[0],
        encoderConfig,
      });
      const audioTrack = await AgoraRTC.createCustomAudioTrack({
        mediaStreamTrack: mediaStream.getAudioTracks()[0],
      });

      resolve(RCStream.createLocalStream(client, videoTrack, audioTrack));
    };

    videoEl.onerror = () => {
      reject(new Error("Failed to load video data"));
    };
  });
}

/**
 * Creates a new WelcomeAV stream
 *
 * @param {RCStreamConfig} streamConfig Config of the stream
 * @returns {Promise<RCStream>} Stream object
 */
export async function createStream({
  client, // RCClient
  videoUrl, // URL of a video file to stream
  microphone = true, // string (device id) | bool
  camera = true, // string (device id) | bool
  screen = false, // bool
  screenAudio = true, // bool
  videoEncoderConfiguration = undefined, // 3.x video encoder configuration
}) {
  if (videoUrl) {
    return _createVideoFileStream(client, videoUrl);
  }

  if (screen) {
    return _createScreenshareStream(
      client,
      screenAudio,
      videoEncoderConfiguration
    );
  }

  if (!camera) {
    WLog.log("info", "welcomeav.streaming", "Creating stream without a camera");
  }

  return _createHardwareStream(
    client,
    typeof camera === "string" || typeof camera === "boolean"
      ? camera
      : undefined,
    typeof microphone === "string" || typeof microphone === "boolean"
      ? microphone
      : undefined,
    videoEncoderConfiguration
  );
}

export async function createAgoraAudioVideoTracks({
  cameraId,
  microphoneId,
  encoderConfig = {
    bitrateMax: VIDEO_CONFIGURATION.MEDIUM_STAGE.bitrate.max,
    bitrateMin: VIDEO_CONFIGURATION.MEDIUM_STAGE.bitrate.min,
    frameRate: VIDEO_CONFIGURATION.MEDIUM_STAGE.frameRate.max,
    height: VIDEO_CONFIGURATION.MEDIUM_STAGE.resolution.height,
    width: VIDEO_CONFIGURATION.MEDIUM_STAGE.resolution.width,
  },
}) {
  let videoTrack = null;
  let audioTrack = null;
  if (cameraId !== false) {
    // The reason we specifically pass `undefined` to Agora is to be compatible with the previous
    // behavior: http://go/c50adaeb
    // TODO: figure out the behavior in case of `undefined` and sanitize this
    [audioTrack, videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        microphoneId:
          typeof microphoneId === "string" ? microphoneId : undefined,
        encoderConfig: "high_quality",
      },
      {
        cameraId: typeof cameraId === "string" ? cameraId : undefined,
        encoderConfig,
        optimizationMode: "motion",
      }
    );
  } else {
    audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId,
      encoderConfig: "high_quality",
    });
  }
  return { audioTrack, videoTrack };
}
