import EventEmitter from "events";
import cloneDeep from "lodash/cloneDeep";
import AV_PERMISSION_REQUEST_TYPE from "~~/constants/avPermissionsRequestType";
import CLIENT_TYPE from "~~/constants/clientType";
import INVITE_STATUS from "~~/constants/inviteStatus";
import * as actions from "~~/redux/actions/video";
import store from "~~/redux/store";
import { showPeopleNotification } from "~~/services/panelNotificationService";
import { fetchRegs } from "~~/services/registrationService";
import {
  adjustStageResolution,
  streamHardwareToPrimaryClient,
  updateStreamSubscriptions,
  updateStreamVolumes,
  MAX_GREEN_ROOM_STREAMS,
} from "~~/services/videoService";
import { isProducerViewMode } from "~~/services/viewModeService";
import CustomCable from "~~/utils/CustomCable";
import axios from "~~/utils/authenticatedAxios";
import { getFeatureFlag } from "~~/utils/featureFlags";
import { getRegIdFromStreamId } from "~~/utils/streamUtils";
import WLog from "~~/wlog";
import { assert } from "~~/wlog/assert";

// Constants
const CHANNEL = "StageSnapshotChannel";

let subscription = null;

const emitter = new EventEmitter();
const events = [
  "greenRoomInviteReceived",
  "greenRoomInviteRescinded",
  "speakerRequestButGreenRoomFull",
  "speakerIntroduced",
];

const state = () => store.getState();

/*
=======================================================================================================
PRIVATE METHODS
=======================================================================================================
*/

/**
 * @param {Object} snapshot The snapshot data
 */
async function _onNewSnapshotReceived(snapshot) {
  const {
    Video: {
      stageSnapshot: {
        onStageStreams: oldOnStageStreams,
        invitedPeople: oldInvitedPeople,
        handRaisedPeople: oldHandRaisedPeople,
        greenRoomRequestPeople: oldGreenRoomRequestPeople,
      },
      streams,
      localClients: { [CLIENT_TYPE.PRIMARY]: primaryClientData = {} },
    },
    Registrations: registrations,
    User: { registration },
  } = store.getState();

  const { stream: primaryStream } = primaryClientData;

  const producerView = isProducerViewMode();

  // Speakers introduced (put on stage)?
  if (
    /* eslint-disable-next-line eqeqeq */
    snapshot.stageScreenshareStreamId == null &&
    snapshot.onStageStreams.length > 0
  ) {
    const newStreams = snapshot.onStageStreams.filter(
      (stream) => !oldOnStageStreams.includes(stream)
    );

    if (newStreams.length > 0) {
      const regIds = newStreams.map((stream) => getRegIdFromStreamId(stream));
      const regsToFetch = [];
      regIds.forEach((regId) => {
        if (!registrations[regId]) {
          regsToFetch.push(regId);
        }
      });
      if (regsToFetch.length > 0) {
        fetchRegs(regsToFetch)
          .then(() => {
            emitter.emit(
              "speakerIntroduced",
              regIds.map((regId) => state().Registrations[regId])
            );
          })
          .catch(console.error);
      } else {
        emitter.emit(
          "speakerIntroduced",
          regIds.map((regId) => state().Registrations[regId])
        );
      }
    }
  }

  const regsForProducersToFetch = [];

  // Handle invitations
  // If I already have a primary stream published, IGNORE any invites seen here. I'm already in the green room!
  const invitationStatus = snapshot.invitedPeople[registration.id]
    ? snapshot.invitedPeople[registration.id].status
    : undefined;
  const oldInvitationStatus =
    oldInvitedPeople && oldInvitedPeople[registration.id]
      ? oldInvitedPeople[registration.id].status
      : undefined;
  if (
    oldInvitationStatus !== INVITE_STATUS.INVITED &&
    invitationStatus === INVITE_STATUS.INVITED &&
    !primaryStream
  ) {
    emitter.emit("greenRoomInviteReceived");
  }
  // Only producers need to know which people are invited on stage
  if (producerView) {
    regsForProducersToFetch.push(...Object.keys(snapshot.invitedPeople));
  }

  // Has my invitation been rescinded?
  if (
    !invitationStatus &&
    !primaryStream &&
    oldInvitationStatus === INVITE_STATUS.INVITED
  ) {
    emitter.emit("greenRoomInviteRescinded");
  }

  // Handle Hand Raised Notifications
  if (
    Object.keys(snapshot.handRaisedPeople).length ===
      Object.keys(oldHandRaisedPeople).length + 1 &&
    producerView
  ) {
    showPeopleNotification();
  }
  // Only producers need to know which people have raised their hands
  if (producerView) {
    regsForProducersToFetch.push(...Object.keys(snapshot.handRaisedPeople));
  }

  // Handle "speaker request but green room is full" notification
  let speakerRequestButGreenRoomFullRegId = undefined;
  let emitSpeakerRequestButGreenRoomFullAfterFetchingRegs = false;
  if (getFeatureFlag("releaseUpdatedSpeakerFlow")) {
    for (const [regId, request] of Object.entries(
      snapshot.greenRoomRequestPeople
    )) {
      const oldRequest = oldGreenRoomRequestPeople[regId];
      if (
        producerView &&
        request.status === "requested" &&
        oldRequest?.status !== "requested" &&
        Object.keys(streams).length >= MAX_GREEN_ROOM_STREAMS
      ) {
        speakerRequestButGreenRoomFullRegId = regId;
        const reg =
          store.getState().Registrations[speakerRequestButGreenRoomFullRegId];
        if (reg) {
          emitter.emit("speakerRequestButGreenRoomFull", { registration });
        } else {
          emitSpeakerRequestButGreenRoomFullAfterFetchingRegs = true;
        }

        break;
      }
    }

    // ...also fetch speakers who've requested to join the green room
    if (producerView) {
      regsForProducersToFetch.push(
        ...Object.keys(snapshot.greenRoomRequestPeople)
      );
    }
  }

  // Fetch the registrations
  if (producerView) {
    fetchRegs(regsForProducersToFetch)
      .then(() => {
        if (
          !getFeatureFlag("releaseUpdatedSpeakerFlow") ||
          !emitSpeakerRequestButGreenRoomFullAfterFetchingRegs
        ) {
          return;
        }

        const reg =
          store.getState().Registrations[speakerRequestButGreenRoomFullRegId];
        if (
          assert(
            !!reg,
            "speakerrequests",
            "Expected to fetch speakers who have requested to join the green room"
          )
        ) {
          emitter.emit("speakerRequestButGreenRoomFull", { registration });
        }
      })
      .catch(console.error);
  }

  updateStreamSubscriptions(snapshot);
  await store.dispatch(actions.setStageSnapshot(snapshot));
  adjustStageResolution();
  updateStreamVolumes();
}

function _computeDynamicFields(data) {
  // Use speakerTimerSecsRemaining to compute speakerTimer field
  // The server will compute speakerTimerSecsRemaining,
  // which the client will use to compute the absolute time that the timer should be zero, but
  // based on its local time rather than the server's time.
  if (data.speakerTimer && data.speakerTimerSecsRemaining) {
    const updatedData = cloneDeep(data);
    updatedData.speakerTimer = new Date(
      Date.now() + data.speakerTimerSecsRemaining * 1000
    ).toISOString();
    return updatedData;
  }
  return data;
}

/*
=======================================================================================================
PUBLIC METHODS
=======================================================================================================
*/
export function on(evt, callback) {
  if (events.indexOf(evt) !== -1) {
    emitter.on(evt, callback);
  } else {
    WLog.assertionFailure(
      "stage.stagesnapshot",
      `No event ${evt} on stage snapshot`
    );
  }
}

export function off(evt, callback) {
  emitter.off(evt, callback);
}

export async function subscribe(stageId, signedStageId, eventId) {
  subscription = new CustomCable(
    {
      channel: CHANNEL,
      stage_id: stageId,
      signed_stage_id: signedStageId,
      event_id: eventId,
    },
    {
      received: (data) => {
        const transformedData = _computeDynamicFields(data);
        _onNewSnapshotReceived(transformedData);
      },
      connected: () => {
        if (getFeatureFlag("releaseInitialDataOverRestNotWs")) {
          fetchStageSnapshot(store.getState().Event.id, stageId).catch(
            console.error
          );
        }
      },
    }
  );
  await subscription.subscribe();
}

export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
  subscription = undefined;
}

export async function updateStreams(onStageStreams, stageScreenshareStreamId) {
  subscription?.perform("update_streams", {
    onStageStreams,
    stageScreenshareStreamId,
  });
}

export async function fetchStageSnapshot(eventId, stageId) {
  const { data } = await axios.get(
    `/events/${eventId}/stages/${stageId}/stage_snapshots?latest=true`
  );
  const transformedData = _computeDynamicFields(data);
  _onNewSnapshotReceived(transformedData);
}

export function setBackgroundAsset({
  type: backgroundAssetType,
  id: backgroundAssetId,
  agendaSessionUuid: mostRecentlyUsedTimelineSessionId,
}) {
  subscription?.perform("set_background_asset", {
    backgroundAssetType,
    backgroundAssetId,
    mostRecentlyUsedTimelineSessionId,
  });
}

export function setLogoAsset({
  type: logoAssetType,
  id: logoAssetId,
  agendaSessionUuid: mostRecentlyUsedTimelineSessionId,
}) {
  subscription?.perform("set_logo_asset", {
    logoAssetType,
    logoAssetId,
    mostRecentlyUsedTimelineSessionId,
  });
}

export function setOverlayAsset({
  type: overlayAssetType,
  id: overlayAssetId,
  agendaSessionUuid: mostRecentlyUsedTimelineSessionId,
}) {
  subscription?.perform("set_overlay_asset", {
    overlayAssetType,
    overlayAssetId,
    mostRecentlyUsedTimelineSessionId,
  });
}

export function setTimerEndTime(timerEndTime) {
  subscription?.perform("set_timer_end_time", {
    timerEndTime,
  });
}

export function setStageQuestionId(stageQuestionId) {
  subscription?.perform("set_stage_question_id", { stageQuestionId });
}

export function setStagePollHashid(
  pollHashid,
  mostRecentlyUsedTimelineSessionId
) {
  subscription?.perform("set_poll_hashid", {
    pollHashid,
    mostRecentlyUsedTimelineSessionId,
  });
}

/**
 * Raises the current user's hand in the stage snapshot.
 */
export async function raiseHand() {
  subscription?.perform("raise_hand");
}

export async function lowerHand() {
  subscription?.perform("lower_hand");
}

/**
 * @param {Number} userId The user ID.
 */
export function lowerSomeonesHand(userId) {
  if (!userId) {
    return;
  }

  subscription?.perform("lower_someones_hand", { userId });
}

/**
 * Available to speakers whose primary streams are already on the stage
 */
export async function addScreenshareToStage() {
  subscription?.perform("screenshare_to_stage");
}

/**
 * Available to speakers whose screenshare streams are on the stage
 */
export async function removeOwnScreenshareFromStage() {
  subscription?.perform("remove_screenshare_from_stage");
}

export async function inviteToGreenRoom(userId) {
  if (!userId) {
    return;
  }
  subscription?.perform("invite_to_green_room", { userId });
}

/* eslint-disable-next-line consistent-return */
export async function rescindInvitation(userId) {
  if (!userId) {
    return;
  }
  subscription?.perform("rescind_invitation", { userId });
}

export async function removeInviteAndHandRaise() {
  subscription?.perform("remove_invite_and_hand_raise");
}

export async function acceptInvitation() {
  // Stream starting should happen first so that the local stream is set in redux. If the stage snapshot
  // were to update before this happens, it may appear to this user as if their invitation were rescinded.
  // Plus, this makes it so the invitation can't be accepted if the stream fails to publish.
  await streamHardwareToPrimaryClient();

  return removeInviteAndHandRaise();
}

export async function rejectInvitation() {
  subscription?.perform("reject_invitation");
}

export async function leave() {
  subscription?.perform("leave");
}

export function setSpeakerTimer(timeInSeconds) {
  subscription?.perform("set_speaker_timer", { speakerTimer: timeInSeconds });
}

export function removeSpeakerTimer() {
  subscription?.perform("set_speaker_timer", { speakerTimer: null });
}

export function updateInjectStreamVolume(volume) {
  subscription?.perform("update_inject_stream_volume", { volume });
}

export function setMostRecentlyUsedTimelineSessionId(
  mostRecentlyUsedTimelineSessionId
) {
  subscription?.perform("set_most_recently_used_timeline_session_id", {
    mostRecentlyUsedTimelineSessionId,
  });
}

export function requestUserAudioPermissions(eventRegistrationId) {
  const { speakerOptInToTurnOnAvEnabled } = store.getState().Event.organization;

  if (getFeatureFlag("speakerOptInEnabled") && speakerOptInToTurnOnAvEnabled) {
    subscription?.perform("request_av_permissions", {
      eventRegistrationId,
      avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.AUDIO,
    });
  }
}

export function requestUserVideoPermissions(eventRegistrationId) {
  const { speakerOptInToTurnOnAvEnabled } = store.getState().Event.organization;

  if (getFeatureFlag("speakerOptInEnabled") && speakerOptInToTurnOnAvEnabled) {
    subscription?.perform("request_av_permissions", {
      eventRegistrationId,
      avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.VIDEO,
    });
  }
}

export function acceptUserAudioPermissions() {
  subscription?.perform("accept_av_permissions", {
    avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.AUDIO,
  });
}

export function acceptUserVideoPermissions() {
  subscription?.perform("accept_av_permissions", {
    avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.VIDEO,
  });
}

export function rejectUserAudioPermissions() {
  subscription?.perform("reject_av_permissions", {
    avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.AUDIO,
  });
}

export function rejectUserVideoPermissions() {
  subscription?.perform("reject_av_permissions", {
    avPermissionRequestType: AV_PERMISSION_REQUEST_TYPE.VIDEO,
  });
}

export function setUserNoCameraMode(noCameraMode) {
  subscription?.perform("set_user_no_camera_mode", { noCameraMode });
}

export function sendGreenRoomRequest() {
  subscription?.perform("send_green_room_request");
}

export function acceptGreenRoomRequest(eventRegistrationId) {
  subscription?.perform("accept_green_room_request", {
    eventRegistrationId,
  });
}

export function rejectGreenRoomRequest(eventRegistrationId) {
  subscription?.perform("reject_green_room_request", {
    eventRegistrationId,
  });
}
