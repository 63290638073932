/* eslint-disable no-bitwise */ // Bitwise is required for uuidv4 //

/**
 * Generates a v4 spec UUID using crypto (not math.random)
 *
 * This approach uses crypto's random generator to reduce potential
 * for collisions.
 *
 * See here for details: https://gist.github.com/jed/982883
 *
 * @returns {string}
 */
export const randomUuidV4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

function isArray(a) {
  return Array.isArray(a);
}

function isObject(o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
}

function toCamel(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
}

/**
 * This set of functions is for handling snake case to camel case
 */
export function camelize(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = camelize(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => {
      return camelize(i);
    });
  }

  return o;
}
