/**
 * @fileoverview Error for when we timeout joining a client channel
 */
export default class JoinTimeoutError extends Error {
  static NAME = "JoinTimeoutError";

  constructor(message) {
    super(message);
    this.name = JoinTimeoutError.NAME;
  }
}
