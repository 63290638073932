/**
 * Dummy RCStream class for debugging purposes. It has no data and all operations
 * no-op. This must be Playwright tested so that, if the API for RCStream
 * changes, this breaks and we'll know to update this class.
 */
export default class DummyRcStream {
  // eslint-disable-next-line constructor-super
  constructor() {
    this.id = Math.floor(Math.random() * 1e5);
  }

  getId() {
    return this.id;
  }

  isDummyStream() {
    return true;
  }

  getVideoTrack() {}

  getAudioTrack() {}

  getMediaStream() {}

  play() {}

  stop() {}

  isPlaying() {
    return false;
  }

  isLocal() {
    return false;
  }

  async setCameraAndMicrophone() {}

  isAudioMuted() {
    return true;
  }

  isVideoMuted() {
    return true;
  }

  async muteVideo() {}

  async unmuteVideo() {}

  muteAudio() {}

  unmuteAudio() {}

  async setVideoEncoderConfiguration() {}

  setAudioVolume() {}

  getAgoraVideoTrack() {}

  getAgoraAudioTrack() {}

  getAgoraTracks() {
    return [];
  }

  isSubscribed() {
    return true;
  }

  shouldSubscribeToVideo() {
    return false;
  }

  shouldSubscribeToAudio() {
    return false;
  }

  getAgoraRemoteUser() {
    return {};
  }
}
