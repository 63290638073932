import React from "react";

/**
 * Manages state for local & remote stream network qualities across multiple
 * channels.
 *
 * @param {RCClient[]} clients A list of RCClient objects
 * @returns { [streamId]: RCNetworkQuality } Mapping of streamId => network
 * quality. In the future, we will want to group this by channel as well,
 * since streamIds can conflict between different channels. But right now,
 * a user only participates in one channel at once
 */
export default function useStreamNetworkQualities(clients) {
  const [networkQualities, setNetworkQualities] = React.useState({});
  // Make sure we only listen once per channel
  const channelsWithListeners = React.useRef({});

  React.useEffect(() => {
    clients.forEach((client) => {
      if (channelsWithListeners.current[client.channel]) {
        return;
      }

      channelsWithListeners.current[client.channel] = true;
      client.on("network-quality", (networkQuality) => {
        setNetworkQualities((currentNetworkQualities) => ({
          ...currentNetworkQualities,
          [client.clientId]: networkQuality,
          ...client.getRemoteStreamNetworkQualities(),
        }));
      });
    });

    [...Object.keys(channelsWithListeners.current)].forEach((channel) => {
      if (!clients.find((client) => client.channel === channel)) {
        delete channelsWithListeners.current[channel];
      }
    });
  }, [clients]);

  return networkQualities;
}
