import { createSelector } from "reselect";
import CLIENT_TYPE from "~~/constants/clientType";
import SPEAKING_STATUS from "~~/constants/speakingStatus";
import { getRegIdFromStreamId, sortStreamsMeFirst } from "~~/utils/streamUtils";

// Agora's fixed key
export const RTMP_STREAM_KEY = 666;

export const getCurrentRegistration = (state) => state.User.registration;
export const getStreams = (state) => state.Video.streams;
export const getRawOnStageStreamIds = (state) =>
  state.Video.stageSnapshot.onStageStreams;
export const getStageScreenshareStreamId = (state) =>
  state.Video.stageSnapshot.stageScreenshareStreamId;
export const getLocalClients = (state) => state.Video.localClients;
export const getAvPermissionsRequestPeople = (state) =>
  state.Video.stageSnapshot.avPermissionsRequestPeople;

export const getUserAvPermissionsRequests = createSelector(
  [getCurrentRegistration, getAvPermissionsRequestPeople],
  (currentRegistration, avPermissionsRequestPeople) =>
    avPermissionsRequestPeople?.[currentRegistration.id]
);

export const getOnStageStreamIds = createSelector(
  [getRawOnStageStreamIds, getStageScreenshareStreamId, getStreams],
  (streamIds, stageScreenshareScreenId, streams) => {
    const ids = [...streamIds];
    if (stageScreenshareScreenId) {
      ids.push(stageScreenshareScreenId);
    }
    return ids.filter((id) => id in streams).map((id) => parseInt(id, 10));
  }
);

/**
 * The streams in the green room (counting on stage)
 */
export const getGreenRoomStreams = createSelector([getStreams], (streams) => {
  return Object.values(streams)
    .filter((stream) => stream.getId() !== RTMP_STREAM_KEY)
    .sort(sortStreamsMeFirst);
});

export const getNumGreenRoomStreams = createSelector(
  [getGreenRoomStreams],
  (greenRoomStreams) => {
    return greenRoomStreams.length;
  }
);

export const getNumGreenRoomOnlyStreams = createSelector(
  [getGreenRoomStreams, getOnStageStreamIds],
  (greenRoomStreams, onStageStreamIds) => {
    return greenRoomStreams.length - onStageStreamIds.length;
  }
);

/**
 * Gets the status of the current user; whether they're in the green room, on the stage, or in the auditorium.
 * Meant to be used in the attendee view.
 */
export const getUserSpeakingStatus = createSelector(
  [getCurrentRegistration, getGreenRoomStreams, getOnStageStreamIds],
  (currentRegistration, greenRoomStreams, onStageStreamIds) => {
    if (
      greenRoomStreams.find(
        (stream) =>
          getRegIdFromStreamId(stream.getId()) === currentRegistration.id
      )
    ) {
      if (onStageStreamIds.find((id) => id === currentRegistration.id)) {
        return SPEAKING_STATUS.SPEAKING;
      }

      return SPEAKING_STATUS.GREEN_ROOM;
    }

    return SPEAKING_STATUS.VIEWING;
  }
);

export const getIsCurrentUserOnStage = createSelector(
  [getRawOnStageStreamIds, getLocalClients],
  (streamIds, localClients) => {
    const primaryClientData = localClients[CLIENT_TYPE.PRIMARY];
    if (!primaryClientData || !primaryClientData.stream) {
      return false;
    }

    return streamIds.includes(primaryClientData.stream.getId());
  }
);

export const getCurrentUserStream = createSelector(
  [getLocalClients],
  (localClients) => {
    if (
      localClients[CLIENT_TYPE.PRIMARY] &&
      localClients[CLIENT_TYPE.PRIMARY].stream
    ) {
      return [localClients[CLIENT_TYPE.PRIMARY].stream, CLIENT_TYPE.PRIMARY];
    }

    if (
      localClients[CLIENT_TYPE.SECONDARY] &&
      localClients[CLIENT_TYPE.SECONDARY].stream
    ) {
      return [
        localClients[CLIENT_TYPE.SECONDARY].stream,
        CLIENT_TYPE.SECONDARY,
      ];
    }

    return [null, null];
  }
);

export const getCurrentUserScreenShareStream = createSelector(
  [getLocalClients],
  (localClients) => {
    if (
      localClients[CLIENT_TYPE.SCREEN] &&
      localClients[CLIENT_TYPE.SCREEN].stream
    ) {
      return localClients[CLIENT_TYPE.SCREEN].stream;
    }

    if (
      localClients[CLIENT_TYPE.SECONDARY_SCREEN] &&
      localClients[CLIENT_TYPE.SECONDARY_SCREEN].stream
    ) {
      return localClients[CLIENT_TYPE.SECONDARY_SCREEN].stream;
    }

    return null;
  }
);
