/**
 * @fileoverview Utility methods for MediaStreamTrack objects
 */
export function getDeviceId(mediaStreamTrack) {
  if (mediaStreamTrack?.getSettings) {
    return mediaStreamTrack?.getSettings()?.deviceId;
  }

  if (mediaStreamTrack?.getCapabilities) {
    return mediaStreamTrack?.getCapabilities()?.deviceId;
  }

  return undefined;
}
