/**
 * @fileoverview Custom instance of AudioWorkletProcessor that returns the volumes.
 *
 * Read more: https://developers.google.com/web/updates/2017/12/audio-worklet
 */
export default `
//js
class VolumeProcessor extends AudioWorkletProcessor {
  static get parameterDescriptors() {
    return [
      {
        name: "keepAlive",
        defaultValue: true,
      },
      {
        name: "smoothingFactor",
        defaultValue: 0.8, 
      },
      {
        // Report volume at 1/x frames. Note that "process" fires roughly once
        // every 2-3ms
        name: "reportFrequency",
        defaultValue: 25,
      },
    ]
  }

  constructor() {
    super();
    this._volume = 0;
    this._numFramesProcessed = 0;
  }

  process(inputs, _outputs, parameters) {
    const input = inputs[0];

    if (input.length > 0) {
      if (!parameters.keepAlive[0]) {
        return false;
      }

      this._numFramesProcessed++;

      // Note: inputs are down-mixed to mono by default (only one channel)
      const samples = input[0];

      let sum = 0;
      for (let i = 0; i < samples.length; i++) {
        sum += samples[i] * samples[i];
      }

      const rms = Math.sqrt(sum / samples.length);
      this._volume = Math.max(rms, this._volume * parameters.smoothingFactor[0]);
      if (this._numFramesProcessed % parameters.reportFrequency[0] === 0) {
        this.port.postMessage(this._volume * 100);
      }
    }

    return true;
  }
};

registerProcessor("volumeProcessor", VolumeProcessor);
`;
