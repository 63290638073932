import VideoCallType from "~~/constants/VideoCallType";
import ClientType from "~~/constants/clientType";
import { LOUNGE_PARTICIPANT_STATE } from "~~/constants/loungeParticipantState";
import { PING_INTERVAL } from "~~/constants/presence";
import * as loungeDataActions from "~~/redux/actions/loungeData";
import {
  addLoungePresenceForRoom,
  removeLoungePresenceForRoom,
  setLoungePresenceForRoom,
  setLoungePresenceForRooms,
} from "~~/redux/actions/loungePresence";
import {
  addLoungeRoom,
  addLoungeRooms,
  clearLoungeRooms as clearLoungeRoomsAction,
  removeLoungeRooms,
  setLoungeRooms,
} from "~~/redux/actions/loungeRooms";
import * as loungeSessionActions from "~~/redux/actions/loungeSession";
import * as regActions from "~~/redux/actions/registrations";
import store from "~~/redux/store";
import { fetchRegs, fetchRegsForLounge } from "~~/services/registrationService";
import {
  joinVideoCall,
  leaveVideoCall,
  removeClient,
  removeSecondaryClientStream,
  streamHardwareToClient,
} from "~~/services/videoService";
import { isProducerViewMode } from "~~/services/viewModeService";
import { toObject } from "~~/utils/arrayUtils";
import axios from "~~/utils/authenticatedAxios";
import cable from "~~/utils/cable";
import { camelize } from "~~/utils/randomUtils";
import WLog from "~~/wlog";

let subscription = null;
let pingInterval = null;

// Constants
const CABLE_CHANNEL = "LoungeChannel";
const PARTICIPANT_HARD_CAP_MAXIMUM = 16;

const MSG_TYPE = {
  UPDATE_PRESENCE: "update_presence",
  PRESENCE_ADD: "presence_add",
  PRESENCE_REMOVE: "presence_remove",
};

// Private methods
function stage() {
  return store.getState().Stage;
}

function currLoungeSession() {
  return store.getState().LoungeSession;
}

/**
 * Fetches a single lounge table. This will also return an Agora RTC token for the current user.
 */
async function fetchLoungeRoom(id) {
  const { id: stageId, eventId } = stage();
  const { data } = await axios.get(
    `/events/${eventId}/stages/${stageId}/lounge_rooms/${id}`
  );
  return data;
}

export function setCurrentLoungeRoom(room) {
  return store.dispatch(loungeDataActions.setCurrentLoungeRoomId(room.id));
}

export function setCurrentLoungeRoomSpectating(spectating) {
  return store.dispatch(
    loungeDataActions.setCurrentLoungeRoomSpectating(spectating)
  );
}

export function setLoungeSessionWithoutRooms(data) {
  return store.dispatch(loungeSessionActions.setLoungeSession(data));
}

export async function setLoungeSessionWithRooms(data) {
  const { loungeRooms, ...loungeSession } = data;
  return Promise.all([
    store.dispatch(loungeSessionActions.setLoungeSession(loungeSession)),
    store.dispatch(setLoungeRooms(loungeRooms)),
  ]);
}

export async function createLoungeRooms(values) {
  const { id, eventId } = stage();
  const { data } = await axios.post(
    `/events/${eventId}/stages/${id}/lounge_rooms`,
    values
  );
  if (!data) {
    return Promise.resolve();
  }

  return store.dispatch(addLoungeRooms(data));
}

export async function duplicateLoungeRoom(loungeRoomId) {
  const { id, eventId } = stage();
  const { data } = await axios.post(
    `/events/${eventId}/stages/${id}/lounge_rooms`,
    {
      loungeRoomToDuplicateId: loungeRoomId,
    }
  );
  if (!data) {
    return Promise.resolve();
  }

  return store.dispatch(addLoungeRooms(data));
}

export async function updateLoungeRoom(loungeRoomId, values) {
  const { id, eventId } = stage();
  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/lounge_rooms/${loungeRoomId}`,
    values
  );
  if (!data) {
    return Promise.resolve();
  }

  return store.dispatch(addLoungeRooms(data));
}

export async function fetchLoungeSession(fetchLatest) {
  const { id: stageId, eventId } = stage();
  const { id: loungeSessionId } = currLoungeSession();

  const { data } = fetchLatest
    ? await axios.post(`/events/${eventId}/stages/${stageId}/lounge_sessions`)
    : await axios.get(
        `/events/${eventId}/stages/${stageId}/lounge_sessions/${loungeSessionId}`
      );

  return setLoungeSessionWithRooms(data);
}

export async function fetchLoungePresence(rooms) {
  const { id: stageId, eventId } = stage();
  const { id: loungeSessionId } = currLoungeSession();

  const url = `/events/${eventId}/stages/${stageId}/lounge_sessions/${loungeSessionId}`;
  const { data } = await axios.get(url, {
    params: {
      rooms,
      presence: true,
    },
  });

  // Get everyone in room that is participating //
  const regIds = Object.values(data).reduce(
    (acc, { participant, spectator }) =>
      acc.concat(participant).concat(spectator),
    []
  );

  if (regIds.length > 0) {
    await fetchRegsForLounge(regIds);
  }

  return store.dispatch(setLoungePresenceForRooms(data));
}

export async function updateLoungeSessionDefaultMaxParticipantsPerRoom(
  maxParticipants
) {
  return loungeSessionActions.setLoungeSessionDefaultMaxParticipantsPerRoom(
    maxParticipants
  );
}

export async function clearLoungeRooms() {
  const { id: stageId, eventId } = stage();
  const loungeSessionId = store.getState().LoungeSession.id;
  await axios.patch(
    `/events/${eventId}/stages/${stageId}/lounge_sessions/${loungeSessionId}`,
    {
      clearAllRooms: true,
    }
  );

  return store.dispatch(clearLoungeRoomsAction());
}

// FIXME: is this being used anymore?
export async function fetchLoungeRooms() {
  const { id, eventId } = stage();
  const { data } = await axios.get(
    `/events/${eventId}/stages/${id}/lounge_rooms`
  );
  let regIds = [];
  if (data.loungePresence) {
    regIds = Object.values(data.loungePresence).concat.apply(
      [],
      Object.values(data.loungePresence)
    );
  }
  if (regIds.length > 0) {
    await fetchRegs(regIds);
  }
  return Promise.all([
    store.dispatch(setLoungeRooms(data.loungeRooms)),
    store.dispatch(setLoungePresenceForRooms(data.loungePresence)),
  ]);
}

// This is only run after the "initial" message is sent, so it's not exported.
async function fetchInitialLoungePresence(loungeRoomId, presenceHash) {
  // Get everyone in room that is participating //
  const regIds = Object.values(presenceHash).reduce(
    (acc, { participant, spectator }) =>
      acc.concat(participant).concat(spectator),
    []
  );

  if (regIds.length > 0) {
    await fetchRegsForLounge(regIds);
  }

  return store.dispatch(
    setLoungePresenceForRoom({ id: loungeRoomId, presence: presenceHash })
  );
}

// This is only run in response to a presence update, so it's not exported.
async function handleLoungePresenceUpdate(data) {
  const { registration, loungeRoomId, userStatus } = camelize(data);
  // Check for existing registration
  const regs = store.getState().Registrations;
  const ids = Object.keys(regs);
  const erExists = ids.includes(registration.id.toString());
  switch (data.messageType) {
    case MSG_TYPE.PRESENCE_ADD:
      if (!erExists) {
        store.dispatch(
          regActions.addRegistrations(toObject([registration], "id"))
        );
      }
      store.dispatch(
        addLoungePresenceForRoom(loungeRoomId, registration, userStatus)
      );
      break;
    case MSG_TYPE.PRESENCE_REMOVE:
      store.dispatch(
        removeLoungePresenceForRoom(loungeRoomId, registration, userStatus)
      );
      break;
    default:
      break;
  }
}

/**
 * Subscribe to event management websocket channel. This lets us know when relevant events are broadcasted
 */
export async function subscribe(loungeRoomId, loungeParticipantStatus) {
  return new Promise((resolve, reject) => {
    const options = {
      channel: CABLE_CHANNEL,
      lounge_room_id: loungeRoomId,
      user_status: loungeParticipantStatus,
    };

    subscription = cable.subscriptions.create(options, {
      received: (data) => {
        switch (data.messageType) {
          case MSG_TYPE.UPDATE_PRESENCE:
            fetchInitialLoungePresence(
              loungeRoomId,
              JSON.parse(data.presenceHash)
            );
            break;
          case MSG_TYPE.PRESENCE_ADD:
          case MSG_TYPE.PRESENCE_REMOVE:
            handleLoungePresenceUpdate(data);
            break;
          default:
            break;
        }
      },
      connected: () => {
        if (pingInterval) {
          clearInterval(pingInterval);
        }

        pingInterval = setInterval(
          () =>
            subscription?.perform("ping", {
              user_status: loungeParticipantStatus,
            }),
          PING_INTERVAL
        );

        setTimeout(() => subscription?.perform("initial"));

        resolve();
      },
      disconnected: () => {
        if (pingInterval) {
          clearInterval(pingInterval);
        }

        WLog.log("warn", "cable.lounge", `Disconnected: ${CABLE_CHANNEL}`);
      },
      rejected: () => {
        WLog.log("warn", "cable.lounge", `Rejected: ${CABLE_CHANNEL}`);
        reject();
      },
    });
  });
}

export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
}

/**
 * @param {Number} id ID of the lounge room to join
 * @returns A promise resolving to an object with data related to the lounge room.
 * Shape: { screenshareToken }
 */
export async function joinLoungeRoom(id) {
  const room = await fetchLoungeRoom(id);

  const handleError = (e) => {
    store.dispatch(addLoungeRoom(room));
    store.dispatch(setLoungePresenceForRoom(room));
    window.flash_messages.flashError(e.message);
    unsubscribe();
    leaveVideoCall();
  };
  const participantLength = room.presence.participant.length;

  if (
    participantLength >= PARTICIPANT_HARD_CAP_MAXIMUM ||
    (participantLength >= room.maxParticipants && !isProducerViewMode())
  ) {
    const error = new Error(
      "Sorry, this room is full! Don't worry, there are plenty more great conversations to choose from."
    );
    error.name = "RoomFull";
    handleError(error);
    throw error;
  }

  try {
    const clientData =
      store.getState().Video.localClients[ClientType.SECONDARY];
    if (
      clientData?.client?.channel === room.agoraChannel &&
      !clientData?.stream
    ) {
      await subscribe(id, LOUNGE_PARTICIPANT_STATE.PARTICIPANT);
      await streamHardwareToClient(ClientType.SECONDARY);
    } else if (!clientData?.client) {
      await subscribe(id, LOUNGE_PARTICIPANT_STATE.PARTICIPANT);
      await joinVideoCall(
        room.agoraChannel,
        room.agoraRtcToken,
        /* onBeforeInitStream */ null,
        room.agoraEncryptionSecret,
        /* streamHardware */ true,
        /* leaveAuditorium */ true,
        /* channelMode */ room.spectateEnabled ? "live" : "rtc"
      );
    }

    return {
      screenshareToken: room.agoraRtcScreenshareToken,
      channel: room.agoraChannel,
      roomId: id,
      callType: VideoCallType.LOUNGE,
      color: room.color,
      encryptionSecret: room.agoraEncryptionSecret,
      spectating: false,
    };
  } catch (e) {
    handleError(e);
    throw e;
  }
}

/**
 * @param {Number} id ID of the lounge room to join
 *
 * @returns A promise resolving to an object with data related to the lounge room.
 */
export async function spectateLoungeRoom(id) {
  const room = await fetchLoungeRoom(id);

  const { spectateEnabled } = room;

  const handleError = (e) => {
    store.dispatch(addLoungeRoom(room));
    store.dispatch(setLoungePresenceForRoom(room));
    window.flash_messages.flashError(e.message);
    unsubscribe();
    leaveVideoCall();
  };

  if (!spectateEnabled) {
    const error = new Error("Sorry, this room doesn't allow spectators");
    error.name = "RoomFull";
    handleError(error);
    throw error;
  }

  try {
    const clientData =
      store.getState().Video.localClients[ClientType.SECONDARY];
    if (
      clientData?.client?.channel === room.agoraChannel &&
      clientData?.stream
    ) {
      await subscribe(id, LOUNGE_PARTICIPANT_STATE.SPECTATING);
      await removeSecondaryClientStream();
      await removeClient(ClientType.SECONDARY_SCREEN);
    } else if (!clientData?.client) {
      await subscribe(id, LOUNGE_PARTICIPANT_STATE.SPECTATING);
      await joinVideoCall(
        room.agoraChannel,
        room.agoraRtcToken,
        /* onBeforeInitStream */ null,
        room.agoraEncryptionSecret,
        /* streamHardware */ false,
        /* leaveAuditorium */ true,
        /* channelMode */ room.spectateEnabled ? "live" : "rtc"
      );
    }

    return {
      channel: room.agoraChannel,
      roomId: id,
      callType: VideoCallType.LOUNGE_SPECTATE,
      color: room.color,
      encryptionSecret: room.agoraEncryptionSecret,
      spectating: true,
      hashid: room.hashid,
    };
  } catch (e) {
    handleError(e);
    throw e;
  }
}

export async function deleteLoungeRooms(id, deleteAllRoomsWithTopic) {
  const { id: stageId, eventId } = stage();

  const { data } = await axios.delete(
    `/events/${eventId}/stages/${stageId}/lounge_rooms/${id}`,
    {
      data: { deleteAllRoomsWithTopic },
    }
  );

  if (deleteAllRoomsWithTopic) {
    return store.dispatch(removeLoungeRooms(data));
  }

  return store.dispatch(removeLoungeRooms([id]));
}

export async function clearLoungeSession() {
  return store.dispatch(loungeSessionActions.clearLoungeSession());
}

export async function openLoungeSession(durationInMins, announcement = null) {
  const { id, eventId } = stage();
  const { LoungeSession: session } = store.getState();
  if (!session || session.id == null) {
    return Promise.reject(new Error("No Lounge Session"));
  }

  const params = durationInMins
    ? { status: "opened", durationInSeconds: durationInMins * 60 }
    : { status: "opened" };

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/lounge_sessions/${session.id}`,
    { loungeSession: params, openAnnouncement: announcement }
  );

  return setLoungeSessionWithoutRooms(data);
}

export async function closeLoungeSession(durationInMins, announcement = "") {
  const { id, eventId } = stage();
  const { LoungeSession: session } = store.getState();
  if (!session || session.id == null) {
    throw new Error("No Lounge Session");
  }

  const { data } = await axios.patch(
    `/events/${eventId}/stages/${id}/lounge_sessions/${session.id}`,
    {
      closeAnnouncement: announcement || null,
      closeInSeconds: durationInMins * 60,
      loungeSession: {
        status: "closed",
      },
    }
  );

  return setLoungeSessionWithoutRooms(data);
}

export function filterLoungeRoomsByTopicOrName(rooms, query) {
  if (!query) {
    return rooms;
  }

  return rooms.filter((room) => {
    return (
      room.topic.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
      room.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  });
}
