/**
 * @fileoverview Context for stream volumes. This is always-on, meaning if
 * the provider is mounted, every stream passed to this container will have
 * its volume analyzed & returned via state.
 */
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as AudioContainerHelpers from "~~/welcomeav/audio/AudioContainerHelpers";
import AudioProcessingManager from "~~/welcomeav/audio/AudioProcessingManager";
import WLog from "~~/wlog";
export const Context = React.createContext(null);

const ProviderSig = {
  propTypes: {
    children: PropTypes.element.isRequired,
    delegate: PropTypes.shape({
      /**
       * Optional callback that can be used to refresh the audio analysis for
       * all streams when the user wants.
       *
       * For example, if we want to refresh analysis when an audio track is
       * muted, this should be implemented like:
       *
       * delegate={{
       *   listenForRefresh: (triggerRefresh) => {
       *     VideoService.on("streamMuteChanged", triggerRefresh);
       *     return () => {
       *       VideoService.off("streamMuteChanged", triggerRefresh);
       *     }
       *   }
       * }}
       *
       * @param {Function} triggerRefresh Delegate should call this when
       * it wants this component to refresh audio analysis
       */
      listenForRefresh: PropTypes.func,
    }),
  },
  defaultProps: {
    delegate: {},
  },
};

export function Provider(props) {
  // + lounge / breakout / 1:1
  const streamsFromStore = useSelector((state) => state.Video.streams);

  // Mapping of stream ID to AudioProcessingManager objects
  const audioProcessingManagers = React.useRef({});
  // Mapping of stream ID to volume
  const [streamVolumes, setStreamVolumes] = React.useState({});

  const updateProcessors = React.useCallback(async () => {
    const streamsFromStoreList = Object.values(streamsFromStore);
    const streamIds = streamsFromStoreList.map((s) => s.getId());
    streamIds.forEach(async (streamId) => {
      const audioTrack = streamsFromStoreList
        .find((s) => s.getId() === streamId)
        ?.getAudioTrack();
      if (!audioTrack) {
        return;
      }

      /**
       * If the processor already exists, just check if we should
       * replace the source audio track
       */
      if (audioProcessingManagers.current[streamId]) {
        const processingManager = audioProcessingManagers.current[streamId];
        const mediaStream = new MediaStream([audioTrack]);
        processingManager.maybeUpdateSource(mediaStream);
        return;
      }

      /**
       * Create a new processor
       */
      try {
        const mediaStream = new MediaStream([audioTrack]);
        const processingManager = new AudioProcessingManager(
          streamId,
          mediaStream
        );
        audioProcessingManagers.current[streamId] = processingManager;
        WLog.log(
          "debug",
          "welcomeav.audio",
          `Created audio processor for stream ${streamId}`
        );

        // Set up default processors
        AudioContainerHelpers.processVolume(processingManager, (vol) => {
          setStreamVolumes((prevVolumesObj) => {
            const volume = Math.round(vol); // Integer from 0 to 100, no need for floats

            // don't cause re-renders if the previous volume is the same as new
            if (prevVolumesObj[streamId] === volume) {
              return prevVolumesObj;
            }

            return {
              ...prevVolumesObj,
              [streamId]: volume,
            };
          });
        });
      } catch (error) {
        WLog.assertionFailure("welcomeav.streaming", error);
      }
    });

    // Clean up processors for streams that have been removed
    Object.keys(audioProcessingManagers.current)
      .map((s) => parseInt(s))
      .forEach((streamId) => {
        if (streamsFromStoreList.find((s) => s.getId() === streamId)) {
          return;
        }
        const processingManager = audioProcessingManagers.current[streamId];
        processingManager.close();
        delete audioProcessingManagers.current[streamId];
        WLog.log(
          "debug",
          "welcomeav.audio",
          `Deleted audio processor for stream ${streamId}`
        );
      });
  }, [streamsFromStore]);

  React.useEffect(() => {
    updateProcessors();

    if (!props.delegate.listenForRefresh) {
      return () => {};
    }

    return props.delegate.listenForRefresh(
      /* triggerRefresh = */ () => {
        updateProcessors();
      }
    );
  }, [streamsFromStore, props.delegate.refresh]);

  /**
   * Adds a processor for a given stream
   *
   * @param {number} streamId ID of the stream to add the processor for
   * @param {string} processorName The name of the processor to add
   * @param {Function} listener Callback for events passed by the processor
   * @throws {Error} Any errors in adding the processor
   */
  const addProcessor = React.useCallback(
    async (streamId, processorName, listener) => {
      const processingManager = audioProcessingManagers.current[streamId];
      if (!processingManager) {
        return;
      }

      await processingManager.addProcessor(processorName, listener);
      WLog.log(
        "debug",
        "welcomeav.audio",
        `Added ${processorName} for stream ${streamId}`
      );
    },
    []
  );

  /**
   * Removes a processor for a given stream
   *
   * @param {number} streamId ID of the stream to remove the processor for
   * @param {string} processorName The name of the processor to remove
   */
  const removeProcessor = React.useCallback((streamId, processorName) => {
    const processingManager = audioProcessingManagers.current[streamId];
    if (!processingManager) {
      return;
    }

    processingManager.removeProcessor(processorName);
    WLog.log(
      "debug",
      "welcomeav.audio",
      `Removed ${processorName} for stream ${streamId}`
    );
  }, []);

  return (
    <Context.Provider
      value={{
        streamVolumes,
        addProcessor,
        removeProcessor,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

Provider.propTypes = ProviderSig.propTypes;
Provider.defaultProps = ProviderSig.defaultProps;
