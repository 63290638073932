/* eslint-disable camelcase */
// This file manages our frontend interaction with Stream Chat API. For more information on our integration, see
// https://www.notion.so/experiencewelcome/Tribal-Knowledge-9132f545c7fe4d6e97352ddf3d81a7ce#81dcc20a83be4987b68e44341fae32fa
import EventEmitter from "events";
import { StreamChat } from "stream-chat";

import CHAT_CHANNEL_TYPE from "~~/constants/chatChannelType";
import { CHAT_SORT_MOST_RECENT_FIRST } from "~~/constants/chatQueryChannelOptions";
import PANEL_NOTIFICATION_TYPE from "~~/constants/panelNotificationType";
import store from "~~/redux/store";
import { showChatChannelNotification } from "~~/services/panelNotificationService";
import * as RegistrationService from "~~/services/registrationService";
import { isProducerViewMode } from "~~/services/viewModeService";
import axios from "~~/utils/authenticatedAxios";
import { getSecondaryText } from "~~/utils/registrationUtils";
import WLog from "~~/wlog";

export const COOLDOWN_IN_SECONDS = 10;

const emitter = new EventEmitter();

export const CustomMessage = {
  VIDEO_CALL_INITIATE: "VIDEO_CALL_INITIATE",
  VIDEO_CALL_LEAVE: "VIDEO_CALL_LEAVE",
  VIDEO_CALL_JOIN: "VIDEO_CALL_JOIN",
  HELP_CHAT_RESOLVED: "HELP_CHAT_RESOLVED",
  CTA_ON_STAGE: "CTA_ON_STAGE",
};

/**
 * Determines if the message is a custom message defined in CustomMessage,
 * and if so, returns that custom message value. Else, returns undefined.
 */
export function detectCustomMessage(messageText) {
  // Handle the case when messageText is not a string. This happened here:
  // https://welcomeonlineco.slack.com/archives/C02P5PYPA3A/p1699363204305339
  // but we don't know why message text is not a string. So we're going to log
  // it and return undefined.
  if (typeof messageText !== "string") {
    // @ts-ignore
    Sentry?.captureMessage(
      `detectCustomMessage: messageText is not a string ${messageText}`
    );
    return {
      messageType: undefined,
      messageData: {},
    };
  }

  const [messageType, messageDataStr] = messageText.split("::");
  if (!CustomMessage[messageType]) {
    return {
      messageType: undefined,
      messageData: {},
    };
  }

  let messageData;
  try {
    messageData = JSON.parse(messageDataStr);
  } catch (err) {
    messageData = {};
    console.error(err);
  }

  return {
    messageType: CustomMessage[messageType],
    messageData,
  };
}

export function createCustomMessage(messageType, messageData) {
  return `${messageType}::${JSON.stringify(messageData)}`;
}

export const chatClient = new StreamChat(window.CLIENT_ENV.STREAM_CHAT_KEY, {
  timeout: 15000,
});

function emitEventForDirectMessage(e, channel) {
  /* eslint-disable-next-line no-case-declarations */
  const { messageType: customMessageType } = detectCustomMessage(
    e.message.text
  );
  if (customMessageType) {
    switch (customMessageType) {
      case CustomMessage.VIDEO_CALL_INITIATE:
        emitter.emit("videoCallInitiated", {
          e,
          channel,
          currentRegistrationId: chatClient.user.registrationId,
        });
        break;
      default:
        break;
    }
  } else {
    emitter.emit("directMessageReceived", e);
  }
}

export function disconnectChatClient() {
  return chatClient.disconnectUser();
}

export async function getPublicChannelForStage(stage) {
  const channelType = stage.highChatTrafficExpected
    ? CHAT_CHANNEL_TYPE.PERFORMANCE
    : CHAT_CHANNEL_TYPE.LIVESTREAM;

  const publicChannel = chatClient.channel(
    channelType,
    `${stage.hashid}-public`,
    {
      name: stage.hashid,
      stage_id: stage.hashid,
      welcome_type: "public",
    }
  );
  if (!chatClient.activeChannels[publicChannel.cid]?.initialized) {
    await publicChannel.watch();
  }

  return publicChannel;
}

export async function getStaffChannelForStage(stage) {
  const staffChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.TEAM,
    `${stage.hashid}-staff`,
    {
      stage_id: stage.hashid,
      welcome_type: "staff",
    }
  );
  if (
    !chatClient.activeChannels[staffChannel.cid] ||
    !chatClient.activeChannels[staffChannel.cid].initialized
  ) {
    await staffChannel.watch();
  }

  return staffChannel;
}

export async function getGreenRoomChannelForStage(stage) {
  const greenRoomChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.TEAM,
    `${stage.hashid}-green-room`,
    {
      stage_id: stage.hashid,
      welcome_type: "green-room",
    }
  );
  if (
    !chatClient.activeChannels[greenRoomChannel.cid] ||
    !chatClient.activeChannels[greenRoomChannel.cid].initialized
  ) {
    await greenRoomChannel.watch();
  }

  return greenRoomChannel;
}

export async function getBreakoutChatChannel(stage, breakoutRoom) {
  const breakoutChatChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.LIVESTREAM,
    `${stage.hashid}-breakout-${breakoutRoom.hashid}`,
    {
      name: breakoutRoom.name,
      stage_id: stage.hashid,
      breakout_room_id: breakoutRoom.hashid,
      welcome_type: "breakout",
    }
  );
  if (
    !chatClient.activeChannels[breakoutChatChannel.cid] ||
    !chatClient.activeChannels[breakoutChatChannel.cid].initialized
  ) {
    await breakoutChatChannel.watch();
  }

  return breakoutChatChannel;
}

export async function getLoungeChatChannel(stage, loungeRoom, chatUserId) {
  const loungeChatChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.LIVESTREAM,
    `${stage.hashid}-lounge-${loungeRoom.hashid}`,
    {
      name: loungeRoom.topic,
      stage_id: stage.hashid,
      lounge_room_id: loungeRoom.hashid,
      welcome_type: "lounge",
    }
  );
  if (
    !chatClient.activeChannels[loungeChatChannel.cid] ||
    !chatClient.activeChannels[loungeChatChannel.cid].initialized
  ) {
    await loungeChatChannel.watch();
    await loungeChatChannel.addMembers([chatUserId]);
  }

  return loungeChatChannel;
}

export function getLoungeChatChannelSync(stage, loungeRoom, chatUserId) {
  if (!stage || !loungeRoom || !chatUserId) {
    WLog.assertionFailure(
      "chat",
      `getLoungeChatChannelSync: Unexpected null parameter. Stage: ${
        stage == null
      }, Room: ${loungeRoom == null}, UID: ${chatUserId == null}`
    );
    return undefined;
  }

  const loungeChatChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.LIVESTREAM,
    `${stage.hashid}-lounge-${loungeRoom.hashid}`,
    {
      name: loungeRoom.topic,
      stage_id: stage.hashid,
      lounge_room_id: loungeRoom.hashid,
      welcome_type: "lounge",
    }
  );
  if (
    !chatClient.activeChannels[loungeChatChannel.cid] ||
    !chatClient.activeChannels[loungeChatChannel.cid].initialized
  ) {
    loungeChatChannel.watch();
    loungeChatChannel.addMembers([chatUserId]);
  }

  return loungeChatChannel;
}

export async function getHelpChatChannel(stage, eventRegistration) {
  // Add all staff registrations to channel
  // TODO: Add ALL registrations (not just online) might fix the bugs?
  const staffRegistrations = Object.values(
    store.getState().Registrations
  ).filter((er) => er.registrationType === "staff");

  const staffRegistrationIds = staffRegistrations.map((sr) => sr.hashid);

  const channelMembers = [...staffRegistrationIds, eventRegistration.hashid];

  const helpChatChannel = chatClient.channel(
    CHAT_CHANNEL_TYPE.LIVESTREAM,
    `${stage.hashid}-help-${eventRegistration.hashid}`,
    {
      name: `${eventRegistration.firstName} ${eventRegistration.lastName}`,
      description: getSecondaryText(eventRegistration),
      image: eventRegistration.profilePhotoUrl,
      stage_id: stage.hashid,
      event_registration_id: eventRegistration.hashid,
      welcome_type: "help",
      members: channelMembers,
    }
  );
  if (
    !chatClient.activeChannels[helpChatChannel.cid] ||
    !chatClient.activeChannels[helpChatChannel.cid].initialized
  ) {
    await helpChatChannel.watch();
  }

  return helpChatChannel;
}

export async function getHelpChatChannelForRegistrationId(
  stage,
  registrationId
) {
  let registration = store.getState().Registrations[registrationId];
  if (!registration) {
    await RegistrationService.fetchReg(registrationId);
    registration = store.getState().Registrations[registrationId];
  }
  return getHelpChatChannel(stage, registration);
}

// Stream chat requires "admin" access to truncate a channel. Rather than elevating all staff,
// we'll check that they are a Producer and then perform the truncate action server side.
export async function clearMessagesInPublicChannel(stage) {
  await axios.delete(
    `/events/${stage.eventId}/stages/${stage.id}/stream_chat/bulk_delete`
  );
}

export async function stopWatchingGreenRoomChannelForStage(stage) {
  const grc = chatClient.channel("team", `${stage.hashid}-green-room`, {
    stage_id: stage.hashid,
    welcome_type: "green-room",
  });

  if (
    chatClient.user &&
    chatClient.activeChannels[grc.cid] &&
    chatClient.activeChannels[grc.cid].initialized
  ) {
    grc.stopWatching();
  }
}

export async function getDmChannel(stage, currentRegistrationId, otherHashid) {
  const dmChannel = chatClient.channel(CHAT_CHANNEL_TYPE.LIVESTREAM, {
    welcome_type: "direct",
    stage_id: stage.hashid,
    // NB: using event id NOT hashid here since it's directly accessible from stage.
    // Consider changing to event hashid for consistency at some point
    event_id: stage.eventId.toString(),
    members: [currentRegistrationId, otherHashid],
  });

  await dmChannel.create();
  await dmChannel.watch();
  return dmChannel;
}

export async function getOtherMemberInDm(channel, currentChatId) {
  const queryMembersResponse = await channel.queryMembers({
    id: { $ne: currentChatId },
  });
  // It's a DM so there's only one other member of the channel
  if (queryMembersResponse.members.length > 0) {
    return queryMembersResponse.members[0].user;
  }
  return {};
}

export async function initChatClient() {
  const { registration: currentRegistration } = store.getState().User;
  const stage = store.getState().Stage;
  const {
    company,
    companyRole,
    firstName,
    lastName,
    profilePhotoUrl,
    registrationType,
    streamChatToken,
    hashid: currentRegistrationHashId,
    id: registrationId,
  } = currentRegistration;
  const shouldWatchStaff = registrationType === "staff";
  const shouldWatchGreenRoom =
    registrationType === "speaker" ||
    registrationType === "moderator" ||
    shouldWatchStaff;

  if (chatClient.user) {
    return Promise.resolve();
  }

  await chatClient.connectUser(
    {
      company,
      companyRole,
      registrationType,
      id: currentRegistrationHashId,
      registrationId,
      image:
        profilePhotoUrl ||
        "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
      name: `${firstName || ""} ${lastName || ""}`,
    },
    streamChatToken
  );

  // Watch appropriate channels so we get notifications
  await getPublicChannelForStage(stage);

  if (shouldWatchStaff) {
    await getStaffChannelForStage(stage);
  }

  if (shouldWatchGreenRoom) {
    await getGreenRoomChannelForStage(stage);
  }

  chatClient.on("notification.added_to_channel", (e) => {
    if (!chatClient.activeChannels[e.cid]) {
      const channel = chatClient.channel(e.channel.type, e.channel.id);
      channel.watch();
    }
  });

  chatClient.on("channel.updated", (e) => {
    if (e.channel.welcome_type === "help") {
      emitter.emit("helpChannelUpdated", e);
    }
  });

  chatClient.on("message.new", (e) => {
    const channel = chatClient.activeChannels[e.cid];

    // Sometimes it's only stored in the internal representation _data
    const channelType =
      channel?.data?.welcome_type || channel?._data?.welcome_type;

    if (e.user.id !== chatClient.user.id) {
      let panelNotificationType = PANEL_NOTIFICATION_TYPE.QUIET;

      const amIMentioned = e.message.mentioned_users
        .map((u) => u.id)
        .includes(chatClient.user.id);

      if (["direct", "staff", "help"].includes(channelType) || amIMentioned) {
        panelNotificationType = PANEL_NOTIFICATION_TYPE.LOUD;
      }

      switch (channelType) {
        case "lounge":
        case "breakout":
        case "public":
        case "green-room":
        case "staff":
          showChatChannelNotification(channelType, panelNotificationType);
          break;
        case "direct":
          showChatChannelNotification("dms", panelNotificationType);
          emitEventForDirectMessage(e, channel);
          break;
        case "help": {
          if (detectCustomMessage(e.message.text)) {
            // TODO: Notifications for custom help messages
            break;
          }

          // FIXME: rough conflation of concerns here
          emitter.emit("helpMessageReceived", e);

          const producerView = isProducerViewMode();
          if (producerView) {
            showChatChannelNotification("help-producer", panelNotificationType);
          } else {
            showChatChannelNotification("help-attendee", panelNotificationType);
          }
          break;
        }
        default:
          WLog.assertionFailure(
            "chat",
            `Unrecognized channel type (${channelType}) or cid (${e.cid})`
          );
          break;
      }
    }
  });

  // Start watching all DM channels and help chats
  // TODO: remove guards, right now we just want to prevent crashes
  try {
    const dmFilters = {
      type: CHAT_CHANNEL_TYPE.LIVESTREAM,
      // NB: using event id NOT hashid here since it's directly accessible from stage.
      // Consider changing to event hashid for consistency at some point
      event_id: stage.eventId.toString(),
      welcome_type: "direct",
      members: { $in: [currentRegistrationHashId] },
      // Below is special syntax for filtering to multiple types of chats in this list, i.e. having breakouts or lounge
      // chat "persist" throughout the event. We flip-flop on this occasionally so leaving the code in for reference
      // $or: [{ welcome_type: "direct" }, { welcome_type: "lounge" }],
    };

    const dmChannels = await chatClient.queryChannels(
      dmFilters,
      CHAT_SORT_MOST_RECENT_FIRST
    );

    dmChannels.forEach((ch) => ch.watch());

    let helpFilters;
    if (shouldWatchStaff) {
      helpFilters = {
        type: CHAT_CHANNEL_TYPE.LIVESTREAM,
        stage_id: stage.hashid,
        welcome_type: "help",
      };
    } else {
      helpFilters = {
        type: CHAT_CHANNEL_TYPE.LIVESTREAM,
        stage_id: stage.hashid,
        welcome_type: "help",
        members: { $in: [currentRegistrationHashId] },
      };
    }

    const helpChannels = await chatClient.queryChannels(
      helpFilters,
      CHAT_SORT_MOST_RECENT_FIRST
    );

    helpChannels.forEach((ch) => ch.watch());
  } catch (e) {
    Sentry?.captureException(e);
  }
  emitter.emit("chatInitialized");

  return Promise.resolve();
}

export function on(event, listener) {
  emitter.on(event, listener);
}

export function off(event, listener) {
  emitter.off(event, listener);
}

export function onChatInitialized(callback) {
  emitter.on("chatInitialized", callback);
}

export function offChatInitialized(callback) {
  emitter.off("chatInitialized", callback);
}

// Our SDK version doesn't include channel.updatePartial
// ...so we have to remove reserved fields before updating data
export async function updateChannel(channel, properties) {
  const data = {
    ...channel.data,
    ...properties,
  };

  // Delete reserved stream chat
  delete data.members;

  return channel.update(data);
}
