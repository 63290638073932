import { getFeatureFlag } from "~~/utils/featureFlags";
import NoAudioTrackError from "~~/welcomeav/audio/noAudioTrackError";
import closedCaptionProcessor from "~~/welcomeav/audio/processors/closedCaptionProcessor";
import volumeProcessor from "~~/welcomeav/audio/processors/volumeProcessor";
import WLog from "~~/wlog";

const { detect } = require("detect-browser");

const browser = detect();

function getProcessorUrl(processorCodeAsString) {
  const blob = new Blob([processorCodeAsString], {
    type: "application/javascript",
  });

  // This can happen in Jest
  if (typeof URL.createObjectURL === "undefined") {
    return;
  }

  return URL.createObjectURL(blob);
}

const Processors = {
  volumeProcessor: getProcessorUrl(volumeProcessor),
  closedCaptionProcessor: getProcessorUrl(closedCaptionProcessor),
};

/**
 * Manages audio processing for a MediaStream instance
 *
 * TODO: This should really just interact with MediaStreamTrack objects instead...
 */
export default class AudioProcessingManager {
  /**
   * @param {number} id The ID to identify what's being processed
   * @param {MediaStream} nativeStream The nativeStream to manage processing for
   * @throws {Error}
   */
  constructor(id, nativeStream) {
    if (nativeStream.getAudioTracks().length === 0) {
      throw new NoAudioTrackError();
    }

    this.id = id;
    if (getFeatureFlag("releaseSafari") && browser.name === "safari") {
      // N.B Safari 14 has an issue where if you add a processing unit to an existing audio track,
      // it will make the audio really distorted. Cloning the media stream will fix this this
      // because it clones the underlying track and adds the processing to the cloned track instead
      // of the original
      this.nativeStream = nativeStream.clone();
    } else {
      this.nativeStream = nativeStream;
    }

    if (typeof AudioContext !== "undefined") {
      this._context = new AudioContext();
    } else if (window.webkitAudioContext) {
      /* eslint-disable-next-line new-cap */
      this._context = new window.webkitAudioContext();
    } else {
      throw new Error("AudioContext API is unavailable");
    }

    this._source = this._context.createMediaStreamSource(this.nativeStream);
    this._workletNodes = {};
    this._isClosed = false;
  }

  /**
   * Updates the audio processing source if needed. If the audio
   * source is the same as the one currently being processed, this
   * will no-op.
   *
   * @param {MediaStream} nativeStream A new stream to process.
   */
  maybeUpdateSource(nativeStream) {
    const newAudioTrack = nativeStream.getAudioTracks()[0];
    if (!newAudioTrack) {
      return;
    }

    const currentAudioTrack = this.nativeStream.getAudioTracks()[0];
    if (currentAudioTrack?.id === newAudioTrack.id) {
      return;
    }

    WLog.log(
      "debug",
      "welcomeav.audio",
      `Updating processing source for stream ${this.id}`
    );

    this._source.disconnect();
    if (getFeatureFlag("releaseSafari") && browser.name === "safari") {
      // N.B Safari 14 has an issue where if you add a processing unit to an existing audio track,
      // it will make the audio really distorted. Cloning the media stream will fix this this
      // because it clones the underlying track and adds the processing to the cloned track instead
      // of the original
      this.nativeStream = nativeStream.clone();
    } else {
      this.nativeStream = nativeStream;
    }
    this._source = this._context.createMediaStreamSource(this.nativeStream);

    // Pipe data to existing nodes
    Object.values(this._workletNodes).forEach((node) => {
      this._source.connect(node);
    });

    // We're phasing out ScriptProcessorNode, but for now we need to pipe
    // the data to the analyzer as well
    if (this._analyzer) {
      this._source.connect(this._analyzer);
    }
  }

  /**
   * Add an AudioWorkletProcessor module to the audio processing.
   *
   * @param {string} processorName The name of the processor. Must be defined in
   * the ./processors directory as a subclass of AudioWorkletProcessor
   * @param {Function} messageListener A listener for messages sent by the audio
   * worklet processor
   * @throws {Error}
   */
  async addProcessor(processorName, messageListener) {
    if (!this._context.audioWorklet) {
      throw new Error("AudioWorklet APIs are unavailable");
    }

    if (this._workletNodes[processorName]) {
      return;
    }

    /* eslint-disable-next-line global-require, import/no-dynamic-require */
    const processorUrl = Processors[processorName];
    if (!processorUrl) {
      throw new Error(
        `Unable to find audio processor with name ${processorName}`
      );
    }

    await this._context.audioWorklet.addModule(processorUrl);
    const node = new AudioWorkletNode(this._context, processorName);
    this._source.connect(node);
    node.connect(this._context.destination);
    node.port.onmessage = messageListener;

    this._workletNodes[processorName] = node;

    WLog.log(
      "debug",
      "welcomeav.audio",
      `Added processor ${processorName} to stream ${this.id}`
    );
  }

  async removeProcessor(processorName) {
    const node = this._workletNodes[processorName];
    if (!node) {
      return;
    }

    const keepAliveParam = node.parameters.get("keepAlive");
    if (keepAliveParam) {
      keepAliveParam.setValueAtTime(false, this._context.currentTime);
    }
    // TODO: Test dis node.disconnect(this._context.destination);
    node.port.close();
    delete this._workletNodes[processorName];

    WLog.log(
      "debug",
      "welcomeav.audio",
      `Removed processor ${processorName} from stream ${this.id}`
    );
  }

  /**
   * Gets the script processor for this stream
   *
   * @returns {[AnalyserNode, ScriptProcessorNode]} Analyzer and script processor
   * for this context
   */
  getAnalyzerAndScriptProcessor() {
    if (this._analyzer && this._scriptProcessor) {
      return [this._analyzer, this._scriptProcessor];
    }

    this._analyzer = this._context.createAnalyser();
    this._analyzer.smoothingTimeConstant = 0.8;
    this._analyzer.fftSize = 1024;

    this._scriptProcessor = this._context.createScriptProcessor(2048, 1, 1);
    this._source.connect(this._analyzer);
    this._analyzer.connect(this._scriptProcessor);
    this._scriptProcessor.connect(this._context.destination);

    WLog.log(
      "debug",
      "welcomeav.audio",
      `Created analyzer and script processor for stream ${this.id}`
    );

    return [this._analyzer, this._scriptProcessor];
  }

  /**
   * Closes the audio context * manages any other cleanup
   */
  close() {
    if (this._isClosed) {
      return;
    }

    this._isClosed = true;
    this._context.close();
  }

  /**
   * Gets the active audio context
   * @returns {AudioContext|null}
   */
  getAudioContext() {
    return this._context;
  }
}
