/**
 * @filoverview Helper methods for network quality
 */
import RCNetworkQuality from "~~/welcomeav/streaming/models/RCNetworkQuality";

/**
 * Converts an Agora network quality value to an RCNetworkQuality value
 *
 * @param {number} agoraNetworkQuality An Agora network quality value.
 * @see https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/networkquality.html#uplinknetworkquality
 */
export function getRCNetworkQuality(agoraNetworkQuality) {
  switch (agoraNetworkQuality) {
    case 1: // Excellent
      return RCNetworkQuality.GREAT;
    case 2: // Good
      return RCNetworkQuality.GOOD;
    case 3: // Slightly impaired
      return RCNetworkQuality.OKAY;
    case 4: // Cannot communicate smoothly
    case 5: // Can barely communicate
      return RCNetworkQuality.POOR;
    case 6: // Disconnected
      return RCNetworkQuality.DISCONNECTED;
    default:
      return RCNetworkQuality.UNKNOWN;
  }
}
