import {
  CLEAR_BREAKOUT_CONFIG,
  SET_JOIN_IMMEDIATELY,
  UPDATE_BREAKOUT_CONFIG,
} from "~~/redux/actionList";

export const updateBreakoutConfig = (data) => ({
  type: UPDATE_BREAKOUT_CONFIG,
  data,
});

export const clearBreakoutConfig = (data) => ({
  type: CLEAR_BREAKOUT_CONFIG,
  data,
});

export const setJoinImmediately = (joinImmediately) => ({
  type: SET_JOIN_IMMEDIATELY,
  joinImmediately,
});
