import {
  REMOVE_BREAKOUT_ROOM,
  SET_BREAKOUT_ROOM,
  UPDATE_BREAKOUT_ROOM,
} from "~~/redux/actionList";

export const setBreakoutRoom = (room) => ({
  type: SET_BREAKOUT_ROOM,
  room,
});

export const updateBreakoutRoom = (room) => ({
  type: UPDATE_BREAKOUT_ROOM,
  room,
});

export const removeBreakoutRoom = () => ({
  type: REMOVE_BREAKOUT_ROOM,
});
