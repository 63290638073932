import PANEL_NOTIFICATION_TYPE from "~~/constants/panelNotificationType";
import {
  setChatChannelNotification,
  setPeopleNotification,
  setPollNotification,
  setQnaNotification,
} from "~~/redux/actions/panelNotifications";
import store from "~~/redux/store";

export function hideChatChannelNotification(key) {
  store.dispatch(setChatChannelNotification(key, PANEL_NOTIFICATION_TYPE.NONE));
  // HACK: help notification should be shown and hidden together, since we set both help-producer
  // and help-attendee notifications so we can keep the chatClient initialization clean and not have
  // to worry about the registration type
  if (key === "help-producer") {
    store.dispatch(
      setChatChannelNotification("help-attendee", PANEL_NOTIFICATION_TYPE.NONE)
    );
  }
}

export function showChatChannelNotification(key, panelNotificationType) {
  store.dispatch(setChatChannelNotification(key, panelNotificationType));
}

export function showQnaNotification() {
  store.dispatch(setQnaNotification(PANEL_NOTIFICATION_TYPE.LOUD));
}

export function hideQnaNotification() {
  store.dispatch(setQnaNotification(PANEL_NOTIFICATION_TYPE.NONE));
}

// This only applies to new raised hands at the moment. If there are other types of people notifications in the future
// this will need to be refactored
export function showPeopleNotification() {
  store.dispatch(setPeopleNotification(PANEL_NOTIFICATION_TYPE.LOUD));
}

export function hidePeopleNotification() {
  store.dispatch(setPeopleNotification(PANEL_NOTIFICATION_TYPE.NONE));
}

export function showPollNotification() {
  store.dispatch(setPollNotification(PANEL_NOTIFICATION_TYPE.LOUD));
}

export function hidePollNotification() {
  store.dispatch(setPollNotification(PANEL_NOTIFICATION_TYPE.NONE));
}
