/**
 * @fileoverview WelcomeAV's stream class. As part of the Agora 4.x migration,
 * this is temporarily equivalent to Agora's 3.x stream object. In future
 * iterations, as much as possible this should be video-provider-agnostic.
 * For simplicity, right now it is not.
 */
import * as EncoderMigrationHelper from "~~/welcomeav/streaming/agoraMiddleware/encoderMigrationHelper";
import AgoraV3Event from "~~/welcomeav/streaming/models/agoraV3Event";
import * as MediaStreamTrackUtils from "~~/welcomeav/streaming/utils/mediaStreamTrackUtils";
import WLog from "~~/wlog";

export default class RCStream {
  /**
   * DO NOT USE. Use the static factory methods provided instead.
   */
  constructor() {
    this.isVideoTrackEnabled = true;
    this.isAudioTrackEnabled = true;
  }

  /**
   * Factory method for creating a local stream
   *
   * @param {RCClient} client WelcomeAV client object
   * @param {ILocalVideoTrack} agoraVideoTrack Agora 4.x video track
   * @param {ILocalAudioTrack} agoraAudioTrack Agora 4.x audio track
   */
  static createLocalStream(client, agoraVideoTrack, agoraAudioTrack) {
    const stream = new RCStream();
    stream.client = client;
    stream.agoraVideoTrack = agoraVideoTrack;
    stream.agoraAudioTrack = agoraAudioTrack;
    if (!agoraVideoTrack) {
      this.isVideoTrackEnabled = false;
    }
    stream.local = true; // Do not use. For 3.x compatibility only
    return stream;
  }

  /**
   * Factory method for creating a remote stream
   *
   * @param {IAgoraRTCRemoteUser} agoraRemoteUser Remote user
   */
  static createRemoteStream(agoraRemoteUser) {
    const stream = new RCStream();
    stream.agoraRemoteUser = agoraRemoteUser;
    stream.local = false; // Do not use. For 3.x compatibility only.
    return stream;
  }

  /**
   * Gets the client ID for this stream.
   *
   * @returns {Number} The client ID
   */
  getId() {
    return this.client?.getId() || this.agoraRemoteUser.uid;
  }

  /**
   * Gets the raw video track
   *
   * @returns {MediaStreamTrack?} The video track of the stream
   */
  getVideoTrack() {
    const agoraVideoTrack = this.getAgoraVideoTrack();
    if (agoraVideoTrack === null) {
      return null;
    }
    const mediaStreamTrack = agoraVideoTrack?.getMediaStreamTrack();
    if (mediaStreamTrack) {
      mediaStreamTrack.screenConstraint = {
        height: agoraVideoTrack._videoHeight,
        width: agoraVideoTrack._videoWidth,
      };
    }
    return mediaStreamTrack;
  }

  /**
   * Gets the raw audio track
   *
   * @returns {MediaStreamTrack} The audio track of the stream
   */
  getAudioTrack() {
    return this.getAgoraAudioTrack()?.getMediaStreamTrack();
  }

  /**
   * Gets a media stream
   *
   * @returns {MediaStream} Media stream with the audio and video tracks
   */
  getMediaStream() {
    const tracks = [];
    if (this.getAudioTrack()) {
      tracks.push(this.getAudioTrack());
    }

    if (this.getVideoTrack()) {
      tracks.push(this.getVideoTrack());
    }

    return new MediaStream(tracks);
  }

  /**
   * Plays the stream
   *
   * @param {String | HTMLElement} element Either a dom ID or <video> element to play
   * the stream on
   * @param {VideoPlayerConfig} config Agora video player config, with keys {fit, mirror}
   */
  play(element, config) {
    if (typeof element === "string") {
      if (!document.getElementById(element)) {
        return;
      }
    }

    this.getAgoraVideoTrack()?.play(element, config);

    if (!this.isLocal()) {
      this.getAgoraAudioTrack()?.play(element, config);
    }

    // Ref. ticket CUS-13711 with Agora. Basically when calling stop() then play()
    // on a stream, the set volume would not be respected. This is the fix that we
    // discovered.
    this._applyLastAudioVolume();
  }

  /**
   * Stops playing the stream
   *
   * @param {boolean} stopTracks Whether to hard-stop the stream by also
   * stopping the underlying media stream tracks. The stream's tracks
   * will no longer be usable.
   */
  stop(stopTracks = false) {
    if (stopTracks) {
      this.getVideoTrack()?.stop();
      this.getAudioTrack()?.stop();
      // close() needs to be called in order to release any processors attached to the
      // video track. Otherwise the camera will remain "on"
      this.getAgoraVideoTrack()?.close();
      this.getAgoraAudioTrack()?.close();
    } else {
      this.getAgoraVideoTrack()?.stop();
      this.getAgoraAudioTrack()?.stop();
    }
  }

  /**
   * Whether or not the stream is current playing
   *
   * @returns {Boolean} Boolean representing whether or not the stream is playing
   */
  isPlaying() {
    const videoTrack = this.getAgoraVideoTrack();
    const audioTrack = this.getAgoraAudioTrack();

    // There is no audio or video track present at all
    if (!videoTrack && !audioTrack) {
      return false;
    }

    // If the video track or audio track is undefined, consider it to be
    // playing. A stream with video playing and no audio (i.e. audio muted)
    // should be considered to be playing.
    const isVideoPlaying = Boolean(videoTrack ? videoTrack.isPlaying : true);
    const isAudioPlaying = Boolean(audioTrack ? audioTrack.isPlaying : true);

    // Local streams never play audio
    if (this.isLocal()) {
      return isVideoPlaying;
    }

    return isVideoPlaying && isAudioPlaying;
  }

  /**
   * Whether or not this is a local stream
   */
  isLocal() {
    return !this.agoraRemoteUser;
  }

  /**
   * Sets the camera & microphone
   *
   * @param {String?} cameraId device ID for the camera
   * @param {String?} microphoneId device ID for the microphone
   */
  async setCameraAndMicrophone(cameraId, microphoneId) {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Setting devices on remote stream"
      );
      return Promise.resolve();
    }

    const promises = [];

    const videoTrack = this.getAgoraVideoTrack();
    const currentCameraId = this._getCameraDeviceId();
    if (cameraId && videoTrack && cameraId !== currentCameraId) {
      promises.push(this.getAgoraVideoTrack()?.setDevice(cameraId));
    }

    const audioTrack = this.getAgoraAudioTrack();
    const currentMicrophoneId = this._getMicrophoneDeviceId();
    if (microphoneId && audioTrack && microphoneId !== currentMicrophoneId) {
      promises.push(this.getAgoraAudioTrack()?.setDevice(microphoneId));
    }

    return Promise.all(promises);
  }

  /**
   * Checks if audio is muted
   */
  isAudioMuted() {
    if (!this.getAgoraAudioTrack()) {
      return true; // Remote streams will hit this condition
    }

    return !this.isAudioTrackEnabled;
  }

  isVideoMuted() {
    if (!this.getAgoraVideoTrack()) {
      return true; // Remote streams will hit this condition
    }

    return !this.isVideoTrackEnabled;
  }

  /**
   * Mutes the video. Must be a local stream
   */
  async muteVideo() {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Called muteVideo on remote stream"
      );
      return;
    }

    try {
      await this.getAgoraVideoTrack()?.setEnabled(false);
      this.isVideoTrackEnabled = false;
      this.client.emit(AgoraV3Event.MUTE_VIDEO);
    } catch (err) {
      WLog.log("warn", "welcomeav.streaming", "Failed to mute video", err);
    }
  }

  /**
   * Mutes the video. Must be a local stream
   */
  async unmuteVideo() {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Called unmuteVideo on remote stream"
      );
      return;
    }

    try {
      await this.getAgoraVideoTrack()?.setEnabled(true);
      this.isVideoTrackEnabled = true;
      this.client.emit(AgoraV3Event.UNMUTE_VIDEO);
    } catch (err) {
      WLog.log("warn", "welcomeav.streaming", "Failed to unmute video", err);
    }
  }

  /**
   * Mutes the audio. Must be a local stream
   */
  muteAudio() {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Called muteAudio on remote stream"
      );
      return;
    }

    this.getAgoraAudioTrack()?.setMuted(true);
    this.isAudioTrackEnabled = false;
    this.client.emit(AgoraV3Event.MUTE_AUDIO);
  }

  /**
   * Mutes the video. Must be a local stream
   */
  unmuteAudio() {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Called muteAudio on remote stream"
      );
      return;
    }

    this.getAgoraAudioTrack()?.setMuted(false);
    this.isAudioTrackEnabled = true;
    this.client.emit(AgoraV3Event.UNMUTE_AUDIO);
  }

  /**
   * Sets the video encoder configuration. The video track of this stream
   * must be an instance of ICameraVideoTrack
   *
   * @param {Object} config Agora 3.x video encoder configuration object
   * @param {Boolean} isScreenshare Whether or not we should update config
   * for screenshare. Should be refactored, since it's Agora-
   * specific logic that should be abstracted away from the caller.
   * @returns {Promise<Void>} Promise
   */
  async setVideoEncoderConfiguration(config, isScreenshare) {
    if (!this.isLocal()) {
      WLog.assertionFailure(
        "welcomeav.streaming",
        "Attempting to set video encoder configuration on remote stream"
      );
      return Promise.resolve();
    }

    const videoTrack = this.getAgoraVideoTrack();
    if (!videoTrack) {
      return Promise.resolve();
    }

    // Check if encoder configurations are the same
    if (
      config.resolution.height === videoTrack._encoderConfig?.height &&
      config.resolution.width === videoTrack._encoderConfig?.width &&
      config.resolution.bitrateMin === videoTrack._encoderConfig?.bitrateMin &&
      config.resolution.bitrateMax === videoTrack._encoderConfig?.bitrateMax &&
      config.frameRate.max === videoTrack._encoderConfig?.frameRate
    ) {
      return Promise.resolve();
    }

    if (isScreenshare) {
      WLog.log(
        "debug",
        "welcomeav.streaming",
        `Applying video track constraints to stream ${this.getId()}`
      );
      return videoTrack
        .getMediaStreamTrack()
        ?.applyConstraints(
          EncoderMigrationHelper.convertToMediaTrackConstraints(config)
        );
    }

    const v4Config = EncoderMigrationHelper.convertToV4Config(config);
    WLog.log(
      "debug",
      "welcomeav.streaming",
      `Setting encoder configuration for stream ${this.getId()}`,
      v4Config
    );
    return videoTrack?.setEncoderConfiguration(v4Config);
  }

  /**
   * Sets the audio volume of the stream. If this is a local stream, the
   * volume will not be changed since in 4.x this changes the volume for
   * everyone.
   *
   * @param {Number} volume The volume
   */
  setAudioVolume(volume) {
    if (this.isLocal()) {
      return;
    }

    this.lastAppliedAudioVolume = volume;
    this.getAgoraAudioTrack()?.setVolume(volume);
  }

  /**
   * Get the Agora video track. When a track is published,
   * Agora 4.x will give us an IAgoraRTCRemoteUser object with null tracks.
   * Only after we subscribe are those tracks made non-null.
   *
   * @returns {ITrack?} Agora video track
   */
  getAgoraVideoTrack() {
    if (this.agoraRemoteUser) {
      return this.agoraRemoteUser.videoTrack;
    }

    return this.agoraVideoTrack;
  }

  /**
   * Get the Agora audio track
   */
  getAgoraAudioTrack() {
    if (this.agoraRemoteUser) {
      return this.agoraRemoteUser.audioTrack;
    }

    return this.agoraAudioTrack;
  }

  /**
   * INTERNAL USE ONLY. For getting the Agora tracks.
   *
   * @returns {ITrack[]} Agora tracks for this stream
   */
  getAgoraTracks() {
    return [this.getAgoraVideoTrack(), this.getAgoraAudioTrack()].filter(
      (x) => x
    );
  }

  /**
   * Whether or not we're subscribed to this stream. 4.x only.
   *
   * @returns {Boolean} Whether or not we're subscribed to this stream
   */
  isSubscribed() {
    // Possibly temporary for 3.x migration... this is used to check if we
    // should play the stream or not, so return "true" for local streams
    if (this.isLocal()) {
      return true;
    }

    return Boolean(
      (!this.agoraRemoteUser.hasVideo || this.agoraRemoteUser.videoTrack) &&
        (!this.agoraRemoteUser.hasAudio || this.agoraRemoteUser.audioTrack)
    );
  }

  /**
   * INTERNAL USE ONLY. Checks if we should subscribe to video of this stream
   *
   * @returns {Boolean} Whether or not we should subscribe to video
   */
  shouldSubscribeToVideo() {
    return (
      !this.isLocal() &&
      this.agoraRemoteUser.hasVideo &&
      !this.agoraRemoteUser.videoTrack // Already subscribed
    );
  }

  /**
   * INTERNAL USE ONLY. Checks if we should subscribe to audio of this stream
   *
   * @returns {Boolean} Whether or not we should subscribe to audio
   */
  shouldSubscribeToAudio() {
    return (
      !this.isLocal() &&
      this.agoraRemoteUser.hasAudio &&
      !this.agoraRemoteUser.audioTrack // Already subscribed
    );
  }

  /**
   * INTERNAL USER ONLY. For getting the agora remote user.
   *
   * @returns {IAgoraRTCRemoteUser?} The Agora remote user object
   */
  getAgoraRemoteUser() {
    return this.agoraRemoteUser;
  }

  _getCameraDeviceId() {
    const videoTrack = this.getAgoraVideoTrack();

    if (videoTrack?._config?.cameraId) {
      return videoTrack._config.cameraId;
    }

    return MediaStreamTrackUtils.getDeviceId(videoTrack?.getMediaStreamTrack());
  }

  _getMicrophoneDeviceId() {
    const audioTrack = this.getAgoraAudioTrack();

    if (audioTrack?._config?.microphoneId) {
      return audioTrack._config.microphoneId;
    }

    return MediaStreamTrackUtils.getDeviceId(audioTrack?.getMediaStreamTrack());
  }

  _applyLastAudioVolume() {
    if (this.isLocal()) {
      return;
    }

    if (this.lastAppliedAudioVolume === undefined) {
      return;
    }

    this.getAgoraAudioTrack()?.setVolume(this.lastAppliedAudioVolume);
  }
}
