/**
 * @fileoverview Contains the context for managing streaming state. Includes
 * clients, streams, connection quality, etc.
 */
import PropTypes from "prop-types";
import React from "react";
import useStreamNetworkQualities from "~~/welcomeav/streaming/useStreamNetworkQualities";

export const Context = React.createContext(null);

const ProviderSig = {
  propTypes: {
    children: PropTypes.element.isRequired,
    // TODO: These should be completely internal within WelcomeAV.
    // Right now, the data exactly mirrors the data in the legacy Redux store.
    // This will change.
    localClients: PropTypes.shape().isRequired,
  },
};

export function Provider(props) {
  const localClientList = React.useMemo(() => {
    return Object.values(props.localClients)
      .map((clientData) => clientData.client)
      .filter((client) => Boolean(client));
  }, [props.localClients]);
  const streamNetworkQualities = useStreamNetworkQualities(localClientList);

  return (
    <Context.Provider
      value={{
        streamNetworkQualities,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

Provider.propTypes = ProviderSig.propTypes;

export function useContext() {
  const context = React.useContent(Context);
  if (!context) {
    throw new Error("container only be used in the context of a provider");
  }

  return context;
}
