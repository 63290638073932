import VirtualBackgroundExtension from "agora-extension-virtual-background";
import store from "~~/redux/store";
import * as mimeTypes from "~~/constants/mimeTypes";
import WLog from "~~/wlog";
import { trackVirtualBackgroundFailed } from "~~/services/analyticsService";

// Map of VideoTrack_Type -> Processor. There should only ever be 2 entries, one for the processor used for AVChecks and one for the local stream.
let processorSingletons = new Map();
// Map of VideoTrackType -> VideoTrack. A processor can only be attached to one video
// at a time. So we always need to keep track of the previous video track for a
// particular videoTrackType so we can cleanup the processor before attaching it to a new
// video track.
let prevVideoTracks = new Map();

export const VirtualBackgroundType = {
  NONE: "none",
  BLUR_LOW: "blur_low",
  BLUR_MEDIUM: "blur_medium",
  BLUR_HIGH: "blur_high",
  IMAGE: "image",
};

export const VideoTrackType = {
  AV_CHECK: "av_check",
  LOCAL_STREAM: "local_stream",
};

/* Initialize the singleton virtual background processor for a specific track type if it doesn't exist. This should only be called the first time a user enables a virtual background. We don't want to create this pre-emptively and waste memory resources if the user never selects a virtual background. Once a virtual background is selected though, the processor will remain in memory of the duration of the user session. We never destroy it, it is re-used every time the user streams anything from that point forward. */
async function getOrCreateProcessorSingleton(videoTrackType) {
  if (!processorSingletons.has(videoTrackType)) {
    WLog.log(
      "debug",
      "welcomeav.virtualbackground",
      `Creating virtual background processor`
    );

    let extension = new VirtualBackgroundExtension();
    AgoraRTC.registerExtensions([extension]);
    let processorSingleton = extension.createProcessor();

    processorSingleton.onoverload = () => {
      processorSingleton.disable();
      trackVirtualBackgroundFailed();
      window.flash_messages.flashError(
        "System resources low. Virtual background disabled. Try closing all other browser tabs & apps and refreshing the page to free up resources."
      );
    };

    try {
      // Initialize the extension and pass in the URL of the Wasm file
      await processorSingleton.init("./assets/wasms");
      processorSingletons.set(videoTrackType, processorSingleton);
    } catch (e) {
      WLog.log(
        "error",
        "welcomeav.virtualbackground",
        "Processor creation failed. Can't load WASM resource!"
      );
      return null;
    }
  }
  return processorSingletons.get(videoTrackType);
}

/**
 * Enable virtual background for videoTrack of videoTrackType. There are only 2 possible videoTrackTypes (AvCheck and LocalStream). We check the videoTrackType in order to clean up the virtual background processor before attaching it to a new video track. This means from a memory usage perspective, there will be 2 virtual background processors in memory at any given time. One for the AVCheck video track and one for the local stream video track.
 */
async function enableVirtualBackround({
  videoTrack,
  options = {},
  videoTrackType = VideoTrackType.AV_CHECK,
}) {
  if (!videoTrack) {
    WLog.log("error", "welcomeav.virtualbackground", "No VideoTrack passed in");
    return null;
  }

  let processor = await getOrCreateProcessorSingleton(videoTrackType);
  let prevVideoTrack = prevVideoTracks.get(videoTrackType);

  if (prevVideoTrack) {
    WLog.log(
      "debug",
      "welcomeav.virtualbackground",
      `Unpiping() processor and videoTrack ${prevVideoTrack.getTrackId()} for videoTrackType ${videoTrackType}`
    );
    processor.unpipe();
    prevVideoTrack.unpipe();
  }
  WLog.log(
    "debug",
    "welcomeav.virtualbackground",
    `Piping() processor into videoTrack ${videoTrack.getTrackId()} of videoTrackType ${videoTrackType}`
  );
  videoTrack.pipe(processor).pipe(videoTrack.processorDestination);
  await processor.setOptions(options);
  await processor.enable();
  prevVideoTracks.set(videoTrackType, videoTrack);
}

// Blur the user's background
export async function setBackgroundBlur({
  blurDegree = 2,
  videoTrack,
  videoTrackType = VideoTrackType.AV_CHECK,
}) {
  let options = { type: "blur", blurDegree: blurDegree };
  enableVirtualBackround({ videoTrack, options, videoTrackType });
}

// Set an image as the background
export async function setBackgroundImage({
  videoTrack,
  virtualBackgroundUrl,
  mimeType,
  videoTrackType = VideoTrackType.AV_CHECK,
}) {
  if (!videoTrack || !virtualBackgroundUrl) {
    return;
  }

  if (mimeTypes.ACCEPTABLE_VIRTUAL_BACKGROUND_FILE_TYPES.includes(mimeType)) {
    const element = document.createElement("img");
    element.crossOrigin = "Anonymous"; // fixes CORS errors
    element.onload = element.oncanplay = async () => {
      let options = { type: "img", source: element };
      enableVirtualBackround({ videoTrack, options, videoTrackType });
    };
    element.src = virtualBackgroundUrl;
  } else {
    // should never happen since we check for this on the upload but just in case
    window.flash_messages.flashError(
      "Unable to set virtual background. That image type is unsupported."
    );
  }
}

// Disable the virtual background. If no virtual background exists, this should no-op.
// What this means in practice is that if a virtual background is never set by a user,
// the extension and processor resources will never be created.
export async function disableVirtualBackground(
  videoTrackType = VideoTrackType.AV_CHECK
) {
  await processorSingletons.get(videoTrackType)?.disable();
}

// Apply virtual background to an RCStream. VideoService primarily uses RCStreams, so
// this function is called to apply the virtual background to the actual published stream
// in green room, lounge, etc. The individual virtual background functions would be used
// for previews like our AVCheck.
export async function applyVirtualBackgroundToRCStream(
  rcStream,
  virtualBackgroundType
) {
  const videoTrack = rcStream.getAgoraVideoTrack();
  const currentUser = store.getState().User.currentUser;
  switch (virtualBackgroundType) {
    case VirtualBackgroundType.BLUR_MEDIUM:
      setBackgroundBlur({
        videoTrack,
        videoTrackType: VideoTrackType.LOCAL_STREAM,
      });
      break;
    case VirtualBackgroundType.IMAGE:
      currentUser?.virtualBackgroundUrl &&
        currentUser?.virtualBackgroundMimeType &&
        setBackgroundImage({
          videoTrack,
          virtualBackgroundUrl: currentUser?.virtualBackgroundUrl,
          mimeType: currentUser?.virtualBackgroundMimeType,
          videoTrackType: VideoTrackType.LOCAL_STREAM,
        });
      break;
    default:
      disableVirtualBackground(VideoTrackType.LOCAL_STREAM);
  }
}
