import {
  CLEAR_LOUNGE_SESSION,
  SET_LOUNGE_SESSION,
  SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM,
} from "~~/redux/actionList";

export const setLoungeSession = (loungeSession) => ({
  type: SET_LOUNGE_SESSION,
  loungeSession,
});

export const setLoungeSessionDefaultMaxParticipantsPerRoom = (
  maxParticipants
) => ({
  type: SET_LOUNGE_SESSION_DEFAULT_MAX_PARTICIPANTS_PER_ROOM,
  maxParticipants,
});

export const clearLoungeSession = () => ({
  type: CLEAR_LOUNGE_SESSION,
});
