/**
 * @fileoverview Functions for getting user devices
 */
import WLog from "~~/wlog";

/**
 * Gets the user's devices
 *
 * @returns {Promise<MediaDeviceInfo[]>} User's connected devices
 */
export async function getDevices(noCamera) {
  try {
    if (noCamera) {
      return await AgoraRTC.getMicrophones();
    }
    return await AgoraRTC.getDevices();
  } catch (error) {
    WLog.log("warn", "welcomeav.capture", `Error getting devices`, error);
    throw error;
  }
}
