/**
 * @fileoverview Actions for the video
 */
import {
  ADD_STREAM,
  REMOVE_ALL_STREAMS,
  REMOVE_CLIENT,
  REMOVE_LOCAL_STREAM,
  REMOVE_STREAM,
  SET_AGORA_RESOLVED_SCHEME,
  SET_CLIENT,
  SET_IS_INJECTION_STREAMING,
  SET_STAGE_SNAPSHOT,
  SET_STAGE_SUB_LEVEL,
  SET_STREAM,
  SET_STREAM_VIDEO_CONFIG,
  UPDATE_AGORA_CONFIG,
} from "~~/redux/actionList";

export const updateAgoraConfig = (config) => ({
  type: UPDATE_AGORA_CONFIG,
  config,
});

export const addStream = (stream) => ({
  type: ADD_STREAM,
  stream,
});

export const removeStreamById = (streamId) => ({
  type: REMOVE_STREAM,
  streamId,
});

export const removeAllStreams = () => ({
  type: REMOVE_ALL_STREAMS,
});

export const removeLocalStream = (clientType) => ({
  type: REMOVE_LOCAL_STREAM,
  clientType,
});

export const setClient = (clientType, client, stream) => ({
  type: SET_CLIENT,
  clientType,
  client,
  stream,
});

export const removeClient = (clientType) => ({
  type: REMOVE_CLIENT,
  clientType,
});

export const setStream = (clientType, stream) => ({
  type: SET_STREAM,
  clientType,
  stream,
});

export const setStageSnapshot = (snapshot) => ({
  type: SET_STAGE_SNAPSHOT,
  snapshot,
});

export const setStageSubLevel = (stageSubLevel) => ({
  type: SET_STAGE_SUB_LEVEL,
  stageSubLevel,
});

export const setIsInjectionStreaming = (isInjectionStreaming) => ({
  type: SET_IS_INJECTION_STREAMING,
  isInjectionStreaming,
});

export const setStreamVideoConfig = (streamId, encoderConfig) => ({
  type: SET_STREAM_VIDEO_CONFIG,
  streamId,
  encoderConfig,
});

export const setAgoraResolvedScheme = (agoraResolvedScheme) => ({
  type: SET_AGORA_RESOLVED_SCHEME,
  agoraResolvedScheme,
});
