import {
  ADD_LOUNGE_PRESENCE_FOR_ROOM,
  REMOVE_LOUNGE_PRESENCE_FOR_ROOM,
  SET_LOUNGE_PRESENCE_FOR_ROOM,
  SET_LOUNGE_PRESENCE_FOR_ROOMS,
} from "~~/redux/actionList";

export const setLoungePresenceForRooms = (presenceHash) => ({
  type: SET_LOUNGE_PRESENCE_FOR_ROOMS,
  presenceHash,
});

export const setLoungePresenceForRoom = (room) => ({
  type: SET_LOUNGE_PRESENCE_FOR_ROOM,
  room,
});

export const addLoungePresenceForRoom = (roomId, er, userStatus) => ({
  type: ADD_LOUNGE_PRESENCE_FOR_ROOM,
  roomId,
  er,
  userStatus,
});

export const removeLoungePresenceForRoom = (roomId, er, userStatus) => ({
  type: REMOVE_LOUNGE_PRESENCE_FOR_ROOM,
  roomId,
  er,
  userStatus,
});
